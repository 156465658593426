import { useWatch } from "react-hook-form";

const TokenDisplay = ({ isUserAdvance, control }: any) => {
  const watchAssetName = useWatch({
    control,
    name: "assetName",
    defaultValue: null,
  });

  const watchTotalAssets = useWatch({
    control,
    name: "totalAssets",
    defaultValue: null,
  });

  const watchUnitName = useWatch({
    control,
    name: "unitName",
    defaultValue: null,
  });

  const watchDecimals = useWatch({
    control,
    name: "decimals",
    defaultValue: 0,
  });

  return (
    <div
      className="flex flex-col items-start rounded-md"
      style={{
        backgroundImage: "linear-gradient(165deg, #3C95DA, #704DDF)",
      }}
    >
      <div className="w-full px-6 pt-4 pb-6  text-white font-semibold">
        {watchAssetName && (
          <p className="text-3xl font-black break-all">{watchAssetName}</p>
        )}
        {!watchAssetName && (
          <div className="w-full animate-pulse flex space-x-4">
            <div className="h-5 bg-blue-400 rounded w-3/4"></div>
          </div>
        )}
        {watchTotalAssets && watchUnitName && (
          <p className="text-lg mt-2 font-bold">
            Max Supply: {watchTotalAssets} {watchUnitName}
          </p>
        )}
        {(!watchTotalAssets || !watchUnitName) && (
          <div className="mt-2 w-full animate-pulse flex space-x-4">
            <div className="h-5 bg-blue-400 rounded w-3/4"></div>
          </div>
        )}
        {isUserAdvance && (watchTotalAssets === null || watchDecimals === null) && (
          <div className="mt-2 w-full animate-pulse flex space-x-4">
            <div className="h-5 bg-blue-400 rounded w-3/4"></div>
          </div>
        )}
        {isUserAdvance && (watchTotalAssets !== null)  && (watchDecimals !== null) && (
          <p className="mt-2 text-lg font-bold">
            Token Units: {watchTotalAssets * 10 ** watchDecimals}
          </p>
        )}
      </div>
    </div>
  );
};

export default TokenDisplay;
