import React, { useState, useEffect } from "react";
import { WalletStore } from "../store/WalletStore";
import { WhiteCard } from "../components/common";
import { Link } from "react-router-dom";
import { FiveXFiveGrid } from "./common";
import PlusGrad from "../assets/plus_gradient.svg";

const algosdk = require("algosdk");

function generateWalletMnemonic() {
  const keys = algosdk.generateAccount();
  const mnemonic = algosdk.secretKeyToMnemonic(keys.sk);
  WalletStore.update((s) => {
    s.mnemonicKey = mnemonic;
  });
}

const CreateWallet = () => {
  // using WalletStore to useState of the wallet key
  const walletKey = WalletStore.useState((s) => s.mnemonicKey);

  useEffect(() => {
    generateWalletMnemonic();
  }, []);

  const [isKeyCopied, setIsKeyCopied] = useState<boolean | undefined>(false);
  const [isBoxChecked, setIsBoxChecked] = useState<boolean | undefined>(false);
  return (
    <div className="grid grid-col-1 lg:grid-cols-3 px-12 gap-12">
      <div className="col-span-full md:col-span-1">
        <ul className="list-disc text-lg text-left">
          <li className="list-none ">
            <p className="text-white font-semibold">What is Algorand?</p>
            <p className="text-tokodot-gray font-medium">
              Algorand is the blockchain on which we create your tokens. Your
              wallet will also exist on the Algorand network.
            </p>
          </li>
          <li className="list-none mt-8">
            <p className="text-white font-semibold">Why do I need a wallet? </p>
            <p className="text-tokodot-gray font-medium">
              You need a wallet to store your tokens. This wallet will also
              exist on the Algorand network.
            </p>
          </li>
          <br></br>
        </ul>
      </div>
      <div className="col-span-2">
        <WhiteCard>
          <div className="flex flex-row mt-2 mb-4 items-center">
            <img className="ml-2 mr-2" src={PlusGrad} width={25} alt="Plus" />
            <p className="font-semibold text-white text-lg">
              Create a new wallet
            </p>
          </div>
          <FiveXFiveGrid>
            {walletKey &&
              walletKey.split(" ").map((word, i) => (
                <div className="flex flex-row px-2 py-3 m-2 text-right text-sm font-semibold overflow-hidden bg-tokodot-dark-secondary text-white space-between rounded">
                  <h1 className="flex select-none">{i + 1}. </h1>
                  <h1 className="flex whitespace-nowrap ml-1">{word}</h1>
                </div>
              ))}
          </FiveXFiveGrid>
          <div className="flex justify-start text-tokodot-primary mt-6  text-xs p-3 rounded-lg ">
            <input
              type="checkbox"
              className="bg-purple-900 text-tokodot-primary"
              onClick={() => {
                setIsBoxChecked(!isBoxChecked);
              }}
            />
            <span className="ml-2 font-semibold text-tokodot-gray">
              I understand that Tokodot will not store this wallet. If I lose
              this wallet, I will lose all the funds in it.
            </span>
          </div>
          <div className="flex flex-row mt-5 justify-between items-center">
            <div className="flex flex-row items-center text-purple-400"></div>
            <div className="">
              <button
                onClick={() => {
                  setIsKeyCopied(true);
                  navigator.clipboard.writeText(walletKey);
                }}
                className="border-2 border-white bg-white text-tokodot-dark-primary font-semibold rounded-lg text-md py-2 px-6 focus:outline-none"
              >
                Copy
              </button>

              <Link to="/verify" className="ml-6">
                <button
                  disabled={!(isKeyCopied && isBoxChecked)}
                  className="disabled:opacity-40 bg-tokodot-primary text-white border-2 border-tokodot-primary font-bold rounded-lg text-lg py-2 px-8"
                >
                  Next
                </button>
              </Link>
            </div>
          </div>
        </WhiteCard>
      </div>
    </div>
  );
};

// const BookOpen = () => (
//   <svg
//     xmlns="http://www.w3.org/2000/svg"
//     width="20"
//     height="20"
//     viewBox="0 0 24 24"
//     fill="none"
//     stroke="currentColor"
//     strokeWidth="2"
//     strokeLinecap="round"
//     strokeLinejoin="round"
//   >
//     <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"></path>
//     <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"></path>
//   </svg>
// );

export const MnemonicPhrase = (props: React.PropsWithChildren<any>) => {
  const walletKey = WalletStore.useState((s) => s.mnemonicKey);
  return (
    <div className="p-2 w-auto m-2 font-semibold text-black-800 bg-blue-100">
      <p>{walletKey}</p>
    </div>
  );
};

export default CreateWallet;
