import React, { PropsWithChildren, useState } from "react";
import { toast } from "react-toastify";
import { MnemonicDisplay } from "./common";

const algosdk = require("algosdk");

export interface ImportWalletProps {
  mnenomicKey?: string;
  importWallet: (mnenomiKey: string) => void;
}

const Card = ({ children }: PropsWithChildren<any>) => (
  <div className="rounded shadow p-8 bg-tokodot-dark-primary ">{children}</div>
);

const Header = ({ children }: PropsWithChildren<any>) => (
  <div className="flex items-center">{children}</div>
);

const Content = ({ children }: PropsWithChildren<any>) => (
  <div className="mt-6 flex flex-col md:flex-row-reverse items-center md:items-start justify-around">
    {children}
  </div>
);
const CardForm = ({ children }: PropsWithChildren<any>) => (
  <div className="flex flex-col items-left">{children}</div>
);

const CardDisplay = ({ children }: PropsWithChildren<any>) => (
  <div className="px-5">{children}</div>
);

const CardButtons = ({ children }: PropsWithChildren<any>) => (
  <div className="mt-6 flex justify-between items-center">{children}</div>
);

export const ImportWallet: React.FC<ImportWalletProps> = ({
  mnenomicKey,
  importWallet,
}) => {
  // const walletKey = WalletStore.useState((s) => s.mnemonicKey);
  const [key, setKey] = useState(() => {
    return mnenomicKey ?? "";
  });
  const validKey = key.trim().split(" ").length === 25;

  const handleUserInputChange = (
    event: React.FormEvent<HTMLTextAreaElement>
  ) => {
    const upatedKey = event.currentTarget.value;
    setKey(upatedKey);
  };

  const handleSubmitChange = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      // check if this is a valid mnemonic
      algosdk.mnemonicToMasterDerivationKey(key);
      importWallet(key);
    } catch (e) {
      toast.error(
        "Could not retrieve account from your mnenomic key. Try again."
      );
      console.error(e);
    }
  };

  return (
    <Card>
      <Header>
        <ImportIcon />
        <h1 className="ml-4 font-semibold text-xl text-white">
          Import Algorand Wallet
        </h1>
      </Header>
      <Content>
        <CardDisplay>
          <p className="text-xs font-bold text-gray-500 mb-1">Key Preview</p>
          <MnemonicDisplay mnemonic={key} textColor={"text-white"} />
        </CardDisplay>
        <CardForm>
          <div>
            <p className="font-black  text-xl mt-6 md:mt-4 text-tokodot-primary">
              Enter your Secret Recovery Phrase
            </p>
            <p className="text-sm text-gray-500">
              Enter a 'space' after each word
            </p>
          </div>
          <form
            onSubmit={handleSubmitChange}
            className="flex flex-col mt-8 space-y-6"
          >
            <textarea
              className="mt-2 mb-8 w-full py-2 px-4 text-md font-medium bg-tokodot-dark-secondary text-white focus:outline-none"
              onInput={handleUserInputChange}
              cols={48}
              value={key}
              style={{ minHeight: "120px", resize: "none" }}
            ></textarea>
            <CardButtons>
              <a
                href="/learn/access"
                className="flex items-center font-semibold text-tokodot-purple hover:underline"
              ></a>
              <button
                className="disabled:opacity-50 px-8 py-3  mx-2 font-black text-white bg-tokodot-primary rounded-lg"
                disabled={!validKey}
              >
                Import
              </button>
            </CardButtons>
          </form>
        </CardForm>
      </Content>
    </Card>
  );
};

const BookOpenIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"></path>
    <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"></path>
  </svg>
);

const ImportIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 123 123"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="w-6"
  >
    <path
      d="M5.19873 20.6494V51.2006H35.7499"
      stroke="#9A93FF"
      strokeWidth="14"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M117.22 102.12V71.5684H86.6685"
      stroke="#80A5FF"
      strokeWidth="14"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M104.439 46.1048C101.857 38.8051 97.4677 32.2788 91.6817 27.1347C85.8956 21.9907 78.9011 18.3966 71.3508 16.6878C63.8005 14.979 55.9404 15.2112 48.5041 17.3627C41.0677 19.5142 34.2975 23.515 28.825 28.9916L5.19873 51.2001M117.22 71.5708L93.5935 93.7772C88.121 99.2538 81.3508 103.255 73.9144 105.406C66.4781 107.558 58.618 107.79 51.0677 106.081C43.5174 104.372 36.5229 100.778 30.7368 95.634C24.9508 90.49 20.5617 83.9637 17.9793 76.664"
      stroke="url(#paint0_radial)"
      strokeWidth="14"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <radialGradient
        id="paint0_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(7.74466 13.0117) rotate(35.6398) scale(166.033 202.929)"
      >
        <stop stopColor="#A18EFF" />
        <stop offset="1" stopColor="#71AFFF" />
      </radialGradient>
    </defs>
  </svg>
);
