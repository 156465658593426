import { NetworkConfig } from "./types";

function generateAlgodUrl(strings: TemplateStringsArray) {
  const ntk_name = strings[0] === "mainnet" ? "" : strings[0] + ".";
  return `https://${ntk_name}algoexplorerapi.io`;
}

function generateIndexerUrl(strings: TemplateStringsArray) {
  return `https://${
    strings[0] === "mainnet" ? "" : strings[0] + "."
  }algoexplorerapi.io/idx2`;
}

function generateExplorerUrl(strings: TemplateStringsArray) {
  return `https://goalseeker.purestake.io/algorand/${strings[0]}`;
}

export const MainnetConfig: NetworkConfig = {
  algodUrl: generateAlgodUrl`mainnet`,
  indexerUrl: generateIndexerUrl`mainnet`,
  apiKey: "",
  explorerUrl: generateExplorerUrl`mainnet`,
  network: "mainnet",
};

export const TestnetConfig: NetworkConfig = {
  algodUrl: generateAlgodUrl`testnet`,
  indexerUrl: generateIndexerUrl`testnet`,
  apiKey: "",
  explorerUrl: generateExplorerUrl`testnet`,
  network: "testnet",
};

export const AlgoExchangePriceURI =
  "https://api.coinbase.com/v2/prices/ALGO-USD/spot";

// TODO: set mainnet to be default in release
export const DefaultConfig = MainnetConfig;
