import {
  DeepMap,
  FieldError,
  FieldValues,
  UseFormRegister,
  UseFormWatch,
  useWatch,
} from "react-hook-form";

const CreateAdvancedToken = (props: {
  register: UseFormRegister<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  errors: DeepMap<FieldValues, FieldError>;
  control: any;
}) => {
  // const watchManager = useWatch({
  //   control,
  //   name: "manager",
  //   defaultValue: "",
  // });

  return (
    <div className="flex flex-col">
      <label
        htmlFor=""
        className="mt-4 text-tokodot-primary font-semibold text-sm"
      >
        Decimals
      </label>
      <input
        {...props.register("decimals", { value: 0 })}
        className="bg-tokodot-dark-secondary text-white text-base px-4 py-3 mt-1 rounded-md"
        name="decimals"
        type="number"
        placeholder="4"
      />
      {props.errors.decimals && (
        <p className="mt-1 ml-1 text-sm text-red-500">
          Decimal has to be greater than 0.
        </p>
      )}
      <label
        htmlFor=""
        className="mt-4 text-tokodot-primary font-semibold text-sm"
      >
        Token Url
      </label>
      <input
        {...props.register("url", {})}
        className="bg-tokodot-dark-secondary text-white text-base px-4 py-3 mt-1 rounded-md"
        name="url"
        maxLength={32}
        placeholder="tokodot.io/nfts"
      />
      {props.errors.url && (
        <p className="mt-1 ml-1 text-sm text-red-500">
          The url has to be shorter than 32 chars
        </p>
      )}
      <label
        htmlFor=""
        className="mt-4 text-tokodot-primary font-semibold text-sm"
      >
        Metadata Hash
      </label>
      <input
        {...props.register("hash", {
          validate: (hash) => hash.length === 32 || hash.length === 0,
        })}
        className="bg-tokodot-dark-secondary text-white text-base px-4 py-3 mt-1 rounded-md"
        name="hash"
        placeholder="#"
      />
      {props.errors.hash && (
        <p className="mt-1 ml-1 text-sm text-red-500">
          Hash can be empty or of exactly 32 bytes
        </p>
      )}
    </div>
  );
};

export default CreateAdvancedToken;
