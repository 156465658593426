import { useEffect, useState } from "react";
import { WhiteCard } from "../common";
import { useForm, useWatch } from "react-hook-form";
import {
  createAlgorandAsset,
  getAlgod,
  sendTransaction,
  waitTransaction,
  walletHasSufficientBalance,
} from "../../lib/utils/lib";
import { WalletStore } from "../../store/WalletStore";
import { Link, Redirect, Route } from "react-router-dom";
import { Routes } from "../../App";
import { toast } from "react-toastify";
import { useHistory, useRouteMatch } from "react-router-dom";
import { tryGetEncryptedKeyFromStorage } from "../../lib/utils/localStorage";
import CreateAdvancedTokenNext from "./CreateAdvancedTokenNext";
import CreateAdvancedToken from "./CreateAdvancedToken";
import BasicToken from "./BasicToken";
import TokenDisplay from "./TokenDisplay";
import PopupDailog from "../PopupDailog";
import { microalgosToAlgos } from "algosdk";

interface AnalyticsWindow extends Window {
  analytics?: any;
}

const Studio = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const {
    register,
    trigger,
    watch,
    handleSubmit,
    reset: formReset,
    formState: { errors },
    setValue,
    getValues,
    control,
  } = useForm();

  const wallet = WalletStore.useState((s) => s.wallet);
  var currProps = WalletStore.useState((s) => s.studioState);
  const [doesWalletExist, setDoesWalletExist] = useState<boolean | boolean>(
    true
  );
  const [lockedWalletExist, setLockedWalletExist] = useState<boolean | boolean>(
    true
  );
  const [isUserAdvance, setIsUserAdvance] = useState<boolean | boolean>(false);
  const [showConfirm, setShowConfirm] = useState<boolean | boolean>(true);
  const [sufficientFunds, setSufficientFunds] = useState<boolean | boolean>(
    true
  );
  const [accountBalance, setAccountBalance] = useState(() => {
    if (wallet) {
      return microalgosToAlgos(Number(wallet?.getAlgoBalance()));
    } else {
      return 0;
    }
  });

  const [showNextAdvancePage, setShowNextAdvancePage] = useState<
    boolean | boolean
  >(false);

  const [waitingForTxn, setWaitingForTxn] = useState(false);
  const [hasUserSubmitted, setHasUserSubmitted] = useState(false);

  // if we have a initial state coming from WalletStore
  useEffect(() => {
    if (currProps) {
      setValue("assetName", currProps.assetName);
      setValue("unitName", currProps.unitName);
      setValue("totalAssets", currProps.totalAssets);
      setValue("decimals", currProps.decimals);
      setValue("url", currProps.url);
      setValue("hash", currProps.hash);
      setValue("manager", currProps.manager);
      setValue("freeze", currProps.freezer);
      setValue("reserve", currProps.reserve);
      setValue("clawback", currProps.clawback);
      setHasUserSubmitted(true);
      if (wallet) {
        const availableBal = microalgosToAlgos(
          Number(
            wallet.getAvailableBalance() > 0 ? wallet.getAvailableBalance() : 1
          )
        );
        if (availableBal >= 0.1 + 0.002) {
          setSufficientFunds(true);
        } else {
          setSufficientFunds(false);
        }
      }
      WalletStore.update((s) => {
        s.studioState = null;
      });
    }
  }, []);

  const watchAssetName = useWatch({
    control,
    name: "assetName",
    defaultValue: null,
  });

  const onCreate = async () => {
    const lockedWalletKey = tryGetEncryptedKeyFromStorage();
    if (!wallet) {
      setDoesWalletExist(false);
      if (lockedWalletKey) {
        setLockedWalletExist(true);
      } else {
        setLockedWalletExist(false);
      }
    } else {
      if (wallet) {
        const availableBal = microalgosToAlgos(
          Number(
            wallet.getAvailableBalance() > 0 ? wallet.getAvailableBalance() : 1
          )
        );
        if (availableBal >= 0.1 + 0.002) {
          setSufficientFunds(true);
        } else {
          setSufficientFunds(false);
          history.push(Routes.STUDIO);
          return;
        }
      }
      setDoesWalletExist(true);
      setWaitingForTxn(true);
      const addr: string = wallet.getAddress();
      let hash = new Uint8Array(32);
      if (getValues("hash")) {
        hash.set(Buffer.from(getValues("hash"), "utf-8"), 0);
      }
      const txn = await createAlgorandAsset(
        wallet.getAddress(),
        getValues("assetName"),
        getValues("unitName"),
        getValues("decimals") === undefined
          ? 0
          : parseInt(getValues("decimals")),
        parseInt(getValues("totalAssets")),
        getValues("url"),
        getValues("hash") === "" ? undefined : hash,
        getValues("manager") === "" ? undefined : getValues("manager"),
        getValues("freeze") === "" ? undefined : getValues("freeze"),
        getValues("frozen"),
        getValues("clawback") === "" ? undefined : getValues("clawback"),
        getValues("reserve") === "" ? undefined : getValues("reserve")
      );
      const txnId = txn.txID().toString();
      const signedTxn = wallet.signTx(txn);
      await sendTransaction(getAlgod(), signedTxn);
      const result = await waitTransaction(txnId);
      const customWindow: AnalyticsWindow = window;
      if (result) {
        toast.success("Success");
        if (customWindow.analytics) {
          const token_type = isUserAdvance
            ? "create_token.advanced.success"
            : "create_token.basic.success";
          customWindow.analytics.track(token_type);
        }
      } else {
        toast.error("Failed");
        if (customWindow.analytics) {
          const token_type = isUserAdvance
            ? "create_token.advanced.failed"
            : "create_token.basic.failed";
          customWindow.analytics.track(token_type);
        }
      }
      setWaitingForTxn(false);
      formReset();
      WalletStore.update((s) => {
        s.studioState = null;
      });
      if (sufficientFunds) {
        history.push(Routes.WALLET);
      }
    }
  };

  const onSubmit = async (props: any) => {
    WalletStore.update((s) => {
      s.studioState = props;
    });
    setHasUserSubmitted(true);
  };

  return (
    <div className="grid grid-col-1 lg:grid-cols-2 px-6 gap-12">
      <div className="col-span-full md:col-span-1">
        <ul className="p-2 list-none text-left">
          <li>
            <p className="text-white font-bold text-xl">What is a token?</p>
            <span className="text-tokodot-gray font-medium text-lg leading-relaxed">
              A token is a scarce asset on the blockchain network. You can
              assign properties on it and create any amount you wish. Once they
              are created, they are immutable, public, and live on-chain
              forever. So make sure to choose an interesting name for your
              token!
            </span>
          </li>
          <li className="mt-6">
            <p className="text-white font-bold text-xl">
              How is a token created?
            </p>
            <span className="text-tokodot-gray font-medium text-lg leading-relaxed">
              Every activity on the chain is a transaction signed for by an
              account, including the creation of a token. Once you finalize your
              token, we use your account to sign an asset creation transaction
              on the Algorand network.
            </span>
          </li>
          <li className="mt-6">
            <p className="text-white font-bold text-xl">
              What can I do with a token?
            </p>
            <span className="text-tokodot-gray font-medium text-lg leading-relaxed">
              You can transfer the tokens to other accounts on the Algorand
              network, so as long as they have consented to participate with
              your token. All transfers of tokens are recorded in the public
              ledger. Anyone can see who initiated the transfer, how many tokens
              were sent, and how many an account holds. This immutable and
              verifiable nature of tokens can be leveraged to divide assets,
              tokenize it, and have a permanent record of the contract.
            </span>
          </li>
        </ul>
      </div>
      <div className="col-span-full md:col-span-1 flex items-center justify-center">
        <div className="w-full md:w-128 text-2xl">
          {!hasUserSubmitted && (
            <div className="grid grid-cols-2 space-x-2 mb-4">
              <button
                className={`flex flex-col rounded-lg p-6 items-center font-bold ${
                  !isUserAdvance
                    ? "bg-tokodot-primary shadow-xl text-white"
                    : "bg-tokodot-dark-primary text-white"
                }`}
                onClick={() => {
                  setIsUserAdvance(false);
                  setShowNextAdvancePage(false);
                  setShowConfirm(true);
                }}
              >
                Basic
              </button>
              <button
                className={`flex flex-col border-0 rounded-lg p-6 items-center font-bold ${
                  !isUserAdvance
                    ? "bg-tokodot-dark-primary text-white"
                    : "bg-tokodot-primary shadow-xl text-white "
                }`}
                onClick={() => {
                  setIsUserAdvance(true);
                  setShowNextAdvancePage(false);
                  setShowConfirm(false);
                }}
              >
                Advanced
              </button>
            </div>
          )}
          <WhiteCard>
            <div className="w-full">
              <TokenDisplay control={control} isUserAdvance={isUserAdvance} />
              {!hasUserSubmitted && (
                <div className="w-full flex flex-col items-center rounded-xl">
                  <form
                    className="w-full flex flex-col"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    {!showNextAdvancePage && (
                      <BasicToken register={register} errors={errors} />
                    )}
                    {isUserAdvance && !showNextAdvancePage && (
                      <div className="flex flex-col">
                        <CreateAdvancedToken
                          register={register}
                          watch={watch}
                          errors={errors}
                          control={control}
                        />
                        <button
                          className="disabled:opacity-40 w-full mt-6 mb-6 py-3 flex items-center justify-center text-lg font-black text-white rounded-lg bg-tokodot-primary focus:outline-none"
                          onClick={async () => {
                            const result = await trigger([
                              "assetName",
                              "unitName",
                              "totalAssets",
                              "hash",
                            ]);
                            if (result) {
                              setShowNextAdvancePage(true);
                              setShowConfirm(true);
                            }
                          }}
                        >
                          Next
                        </button>
                      </div>
                    )}
                    {isUserAdvance && showNextAdvancePage && (
                      <CreateAdvancedTokenNext
                        register={register}
                        errors={errors}
                      />
                    )}
                    {showConfirm && (
                      <button
                        type="submit"
                        className="disabled:opacity-40 w-full mt-6 mb-6 py-3 flex items-center justify-center text-lg font-black text-white rounded-lg bg-tokodot-primary focus:outline-none"
                      >
                        Confirm
                      </button>
                    )}
                  </form>
                </div>
              )}
            </div>
            {hasUserSubmitted && (
              <ConfirmTokenCreation
                onCreate={onCreate}
                waitingForTxn={waitingForTxn}
                setHasUserSubmitted={setHasUserSubmitted}
                sufficientFunds={sufficientFunds}
                acccountBalance={microalgosToAlgos(
                  Number(wallet?.getAlgoBalance() || 1)
                )}
              />
            )}
            {!doesWalletExist && (
              <div>
                <div>
                  {lockedWalletExist && (
                    <Popup type="unlock" route={Routes.UNLOCK} />
                  )}
                  {!lockedWalletExist && (
                    <Popup type="new" route={Routes.NEW} />
                  )}
                </div>
              </div>
            )}
          </WhiteCard>
        </div>
      </div>
    </div>
  );
};

const ConfirmTokenCreation = ({
  onCreate,
  waitingForTxn,
  setHasUserSubmitted,
  sufficientFunds,
  acccountBalance,
}: any) => {
  // const wallet = WalletStore.useState((s) => s.wallet);
  const history = useHistory();

  return (
    <div className="flex flex-col mt-4">
      <p className="font-semibold text-gray-500 text-base">
        Token Creation Fee: <span className="ml-2">0.002 Algos</span>
      </p>
      <p className="font-semibold text-gray-500 text-base">
        Network Fee: <span className="ml-2">0.001 Algos</span>
      </p>
      <p className="font-semibold text-gray-500 text-base">
        Account Algo Balance: <span className="ml-2">{acccountBalance}</span>
      </p>
      {sufficientFunds && (
        <button
          className="w-full mt-4 py-3 flex items-center justify-center text-lg font-black text-white rounded-lg  bg-tokodot-primary focus:outline-none"
          onClick={() => {
            onCreate();
          }}
        >
          {waitingForTxn && <Spinner />}
          Create
        </button>
      )}
      {!sufficientFunds && (
        <div>
          <p className="font-semibold text-red-500 text-base pt-6">
            Insufficient funds
          </p>
          {/* <p className="font-semibold text-white text-base pt-1 pb-2">
            Need atleast 0.002 Algos to create a token
          </p> */}
          <button
            className="w-full mt-4 py-3 flex items-center justify-center text-lg font-black text-white rounded-lg  bg-tokodot-primary focus:outline-none"
            onClick={() => {
              // setHasUserSubmitted(false);
              history.push(Routes.WALLET);
            }}
          >
            Wallet
          </button>
        </div>
      )}
      <button
        className="mt-4 text-red-500 font-medium text-base py-2 focus:outline-none rounded-sm"
        onClick={() => {
          setHasUserSubmitted(false);
        }}
      >
        Cancel
      </button>
    </div>
  );
};

const Popup = (props: any) => {
  return (
    <PopupDailog
      title={`${props.type === "unlock" ? "Unlock" : "Create"} Wallet`}
    >
      <p className="font-semibold mb-4 text-base text-white">
        {props.type === "unlock"
          ? "You need a wallet to create a token. And it looks like you have an existing wallet!"
          : "It looks like you don't have a wallet! You need a wallet to store your new tokens"}
      </p>
      <p className="font-semibold mb-8 text-base text-white">
        {props.type === "unlock"
          ? "For security, we need you to unlock your wallet."
          : "Quickly create a new wallet or import an existing one!"}
      </p>
      <Link to={props.route}>
        <button className="bg-tokodot-primary text-white font-semibold rounded-lg text-base py-2 px-6 focus:outline-none">
          {props.type === "unlock" ? "Unlock Wallet" : "Create Wallet"}
        </button>
      </Link>
    </PopupDailog>
  );
};

const Spinner = () => (
  <svg
    className="animate-spin-fast -ml-1 mr-3 h-5 w-5 text-white"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
);

export default Studio;
