import { Store } from "pullstate";
import { WalletProvider } from "../lib/wallet/Wallet";

interface WalletStoreType {
  wallet?: WalletProvider;
  index: Number;
  mnemonicKey: string;
  studioState?: any;
}

const defaultStore: WalletStoreType = {
  index: 0,
  mnemonicKey: "",
};

export const WalletStore = new Store(defaultStore);
