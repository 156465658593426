import { microalgosToAlgos } from "algosdk";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  claimDrop,
  getAllRevDrops,
  getArgsFromDrop,
  saveAllRevDrops,
} from "../lib/drop/createDrop";
import { AssetBalance } from "../lib/wallet/types";
import { WalletProvider } from "../lib/wallet/Wallet";

function ClaimDrop(props: { wallet: WalletProvider }) {
  const [waitingForTxn, setWaitingForTxn] = useState(false);
  const [dropDetails, setDropDetails] = useState<{
    algos: Number;
    assets: AssetBalance[];
  } | null>(null);

  const all_drops = getAllRevDrops();
  const dropId =
    Object.keys(all_drops).length > 0 ? Object.keys(all_drops)[0] : null;

  const claimTheDrop = async () => {
    if (!dropId) {
      return;
    }
    setWaitingForTxn(true);
    const res = await claimDrop(dropId, props.wallet);
    if (res) {
      toast.success("Successfully claimed the drop!! Woo hoo.");
      delete all_drops[dropId];
      saveAllRevDrops(all_drops);
    }
    setWaitingForTxn(false);
  };

  useEffect(() => {
    if (!dropId) {
      return;
    }
    const getArgs = async () => {
      const res = await getArgsFromDrop(dropId);
      if (res) {
        setDropDetails(res);
      }
    };
    getArgs();
  }, [dropId]);

  if (!dropId || !dropDetails) {
    return null;
  }
  return (
    <div className="mb-12 bg-tokodot-dark-primary p-6 rounded-xl shadow-lg">
      <div className="flex text-white font-black text-lg">
        <Gift />
        <span className="ml-4">You have a drop that you can claim!</span>
      </div>
      <div className="flex items-center justify-between">
        <div className="text-white font-black text-lg mt-3 flex flex-col-reverse">
          <span>{microalgosToAlgos(Number(dropDetails.algos))}</span>
          <span className="text-xs text-tokodot-gray">ALGO</span>
        </div>
        {dropDetails.assets.map((asset, i) => (
          <p
            className="text-white font-black text-lg mt-3 flex flex-col-reverse"
            key={i}
          >
            <span>{asset.balance} tokens</span>
            <span className="text-xs text-tokodot-gray">
              <Link to={"/asset/" + asset.params.assetId}>
                ID: {asset.params.assetId}
              </Link>
            </span>
          </p>
        ))}
        <button
          className="disabled:opacity-40  bg-tokodot-primary text-white py-2 px-3 rounded-lg font-bold mt-4 flex items-center "
          disabled={waitingForTxn}
          onClick={claimTheDrop}
        >
          {waitingForTxn && <Spinner />}
          Claim Now
        </button>
      </div>
    </div>
  );
}

const Spinner = () => (
  <svg
    className="animate-spin-fast -ml-1 mr-3 h-5 w-5 text-white"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
);

const Gift = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <polyline points="20 12 20 22 4 22 4 12"></polyline>
    <rect x="2" y="7" width="20" height="5"></rect>
    <line x1="12" y1="22" x2="12" y2="7"></line>
    <path d="M12 7H7.5a2.5 2.5 0 0 1 0-5C11 2 12 7 12 7z"></path>
    <path d="M12 7h4.5a2.5 2.5 0 0 0 0-5C13 2 12 7 12 7z"></path>
  </svg>
);
export default ClaimDrop;
