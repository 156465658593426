import { TransactionType } from "algosdk";
import {
  AssetTransferTransaction,
  PaymentTransaction,
  Transaction,
  TransactionTypeAttribute,
} from "./types";

export function getTransaction(x: any): Transaction {
  let txn: Transaction = {
    fee: x["fee"],
    id: x["id"],
    note: x["note"],
    sender: x["sender"],
    txType: x["tx-type"],
  };
  const txType: TransactionType = x["tx-type"];
  const txTypeAttr = TransactionTypeAttribute[txType];

  switch (txType) {
    case TransactionType.axfer:
      const axfer: AssetTransferTransaction = {
        amount: x[txTypeAttr]["amount"],
        assetId: x[txTypeAttr]["asset-id"],
        receiver: x[txTypeAttr]["receiver"],
        closeAmount: x[txTypeAttr]["close-amount"],
      };
      txn["asset-transfer-transaction"] = axfer;

      break;
    case TransactionType.pay:
      const pay: PaymentTransaction = {
        amount: x[txTypeAttr]["amount"],
        receiver: x[txTypeAttr]["receiver"],
        closeAmount: x[txTypeAttr]["close-amount"],
      };
      txn["payment-transaction"] = pay;
      break;
    default:
      break;
  }
  return txn;
}
