import { createMachine, assign, State } from "xstate";

const shareMachine = createMachine(
  {
    id: "Share",
    initial: "idle",
    context: {
      sendAlgoRetries: 0,
      optInRetries: 0,
      sendAssetRetries: 0,
    },
    states: {
      idle: {
        on: { SEND_ALGO: "sendingAlgos" },
      },
      sendingAlgos: {
        entry: ["sendAlgos"],
        on: {
          RESOLVE: {
            target: "optingIn",
            actions: ["persist"],
          },
          REJECT: {
            target: "failedSendAlgos",
          },
        },
      },
      sentAlgos: {
        on: { OPTIN: "optingIn" },
      },
      failedSendAlgos: {
        on: {
          RETRY: [
            {
              target: "sendingAlgos",
              actions: assign({
                sendAlgoRetries: (ctx: any, _) => ctx.sendAlgoRetries + 1,
              }),
              cond: {
                type: "shouldRetry",
                counter: "sendAlgoRetries",
                maxRetries: 3,
              },
            },
            {
              target: "errorSendAlgos",
            },
          ],
        },
      },
      optingIn: {
        entry: ["optIn"],
        on: {
          RESOLVE: {
            target: "sendingAsset",
            actions: ["persist"],
          },
          REJECT: {
            target: "failedOptIn",
          },
        },
      },
      optedIn: {
        on: { SEND_ASSET: "sendingAsset" },
      },
      failedOptIn: {
        on: {
          RETRY: [
            {
              target: "optingIn",
              actions: assign({
                optInRetries: (ctx: any, _) => ctx.optInRetries + 1,
              }),
              cond: {
                type: "shouldRetry",
                counter: "optInRetries",
                maxRetries: 3,
              },
            },
            { target: "errorOptIn" },
          ],
        },
      },
      sendingAsset: {
        entry: "sendAsset",
        on: {
          RESOLVE: {
            target: "sentAsset",
            actions: ["persist"],
          },
          REJECT: {
            target: "failedSendAsset",
          },
        },
      },
      sentAsset: {
        type: "final",
      },
      failedSendAsset: {
        on: {
          RETRY: [
            {
              target: "sendingAsset",
              actions: assign({
                sendAssetRetries: (ctx: any, _) => ctx.sendAssetRetries + 1,
              }),
            },
            { target: "errorSendAsset" },
          ],
        },
      },
      errorSendAlgos: {
        type: "final",
      },
      errorOptIn: {
        type: "final",
      },
      errorSendAsset: {
        type: "final",
      },
    },
  },
  {
    actions: {
      persist: (ctx) => {
        // try {
        //   localStorage.setItem("share-xstate", JSON.stringify(ctx));
        // } catch (e) {
        //   console.error(e);
        // }
      },
    },
    guards: {
      shouldRetry: (ctx: any, event, { cond }: any) => {
        return ctx[cond.counter] < cond.maxRetries;
      },
    },
  }
);

const matchesAnyState = (
  states: string[],
  machine: State<any, any, any, any>
) => (states.find((state) => machine.matches(state)) ? true : false);

export { shareMachine, matchesAnyState };
