import algosdk, { isValidAddress } from "algosdk";
import { useEffect, useState } from "react";
import {
  DeepMap,
  FieldError,
  FieldValues,
  UseFormRegister,
} from "react-hook-form";

const CreateAdvancedTokenNext = (props: {
  register: UseFormRegister<FieldValues>;
  errors: DeepMap<FieldValues, FieldError>;
}) => {
  const [canFreezeAsset, setCanFreezeAsset] = useState<boolean | boolean>(
    false
  );

  function isFreezeAddressValid(add: any) {
    if (algosdk.isValidAddress(add)) {
      setCanFreezeAsset(true);
    } else {
      setCanFreezeAsset(false);
    }
  }

  return (
    <div className="flex flex-col">
      <label
        htmlFor=""
        className="mt-4 text-tokodot-primary font-semibold text-sm"
      >
        Manager Address
      </label>
      <input
        {...props.register("manager", {
          required: false,
          validate: (add) => algosdk.isValidAddress(add) || !add,
        })}
        defaultValue={""}
        className="bg-tokodot-dark-secondary text-white text-base px-4 py-3 rounded-xl"
        name="manager"
      />
      {props.errors.manager && (
        <p className="mt-1 ml-1 text-sm text-red-500">
          Enter a valid Algorand address
        </p>
      )}
      <label
        htmlFor=""
        className="mt-4 text-tokodot-primary font-semibold text-sm"
      >
        Reserve Address
      </label>
      <input
        {...props.register("reserve", {
          required: false,
          validate: (add) => algosdk.isValidAddress(add) || !add,
        })}
        defaultValue={""}
        className="bg-tokodot-dark-secondary text-white text-base px-4 py-3 rounded-xl"
        name="reserve"
      />
      {props.errors.reserve && (
        <p className="mt-1 ml-1 text-sm text-red-500">
          Enter a valid Algorand address
        </p>
      )}
      <label
        htmlFor=""
        className="mt-4 text-tokodot-primary font-semibold text-sm"
      >
        Clawback Address
      </label>
      <input
        {...props.register("clawback", {
          required: false,
          validate: (add) => algosdk.isValidAddress(add) || !add,
        })}
        defaultValue={""}
        className="bg-tokodot-dark-secondary text-white text-base px-4 py-3 rounded-xl"
        name="clawback"
      />
      {props.errors.clawback && (
        <p className="mt-1 ml-1 text-sm text-red-500">
          Enter a valid Algorand address
        </p>
      )}
      <label
        htmlFor=""
        className="mt-4 text-tokodot-primary font-semibold text-sm"
      >
        Freeze Address
      </label>
      <input
        {...props.register("freeze", {
          required: false,
          validate: (add) => algosdk.isValidAddress(add) || !add,
        })}
        defaultValue={""}
        className="bg-tokodot-dark-secondary text-white text-base px-4 py-3 rounded-xl"
        name="freeze"
        onChange={(e) => {
          isFreezeAddressValid(e.target.value);
        }}
      />
      {props.errors.freeze && (
        <p className="mt-1 ml-1 text-sm text-red-500">
          Enter a valid Algorand address
        </p>
      )}
      {canFreezeAsset && (
        <div className="flex justify-start text-tokodot-primary mt-6 text-xs rounded-lg ">
          <input
            {...props.register("frozen", {})}
            type="checkbox"
            className="bg-tokodot-primary text-tokodot-primary"
            name="frozen"
          />
          <span className="ml-2 font-semibold">Freeze token by default</span>
        </div>
      )}
    </div>
  );
};

export default CreateAdvancedTokenNext;
