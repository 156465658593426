import React from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Routes } from "../App";

import { ImportWallet } from "../components/ImportWallet";
import { tryGetEncryptedKeyFromStorage } from "../lib/utils/localStorage";
import { MnemonicWallet } from "../lib/wallet/MnemonicWallet";
import { WalletStore } from "../store/WalletStore";
import { Circle } from "../components/common";

const ImportWalletPage = () => {
  const history = useHistory();
  const importWallet = (mnemonic: string) => {
    const wallet = MnemonicWallet.fromMnemonic(mnemonic);
    WalletStore.update((s) => {
      s.mnemonicKey = mnemonic;
    });

    history.push(Routes.LOCK);
  };

  if (tryGetEncryptedKeyFromStorage()) {
    return <Redirect to={Routes.UNLOCK} />;
  }

  return (
    <div className="container mx-auto w-full">
      <div className="flex mt-12 text-2xl p-12">
        <div className="">
          <Circle>2</Circle>
        </div>
        <div className="flex flex-col ">
          <p className="ml-4 font-bold mt-2 text-white">
            Let's import your existing Algorand wallet
          </p>
          {/* <p className="ml-4 font-bold mt-6"></p> */}
        </div>
      </div>
      <div className="mt-12 px-4">
        <ImportWallet importWallet={importWallet} />
      </div>

      <div className="mt-12"></div>
    </div>
  );
};

export default ImportWalletPage;
