import { MnemonicWallet } from "../wallet/MnemonicWallet";

export const WALLET_STORAGE_KEY = "tokodot:wallet:key"

export function loadAndGetWallet(): MnemonicWallet | null{
    const mnemonicKey = window.localStorage.getItem(WALLET_STORAGE_KEY);
    if(mnemonicKey){
      return MnemonicWallet.fromMnemonic(mnemonicKey);
    }
    return null;
}

export function tryGetEncryptedKeyFromStorage(): string | null {
  return window.localStorage.getItem(WALLET_STORAGE_KEY);
}

export function saveEncryptedKey(encryptedKey: string) {
  window.localStorage.setItem(WALLET_STORAGE_KEY, encryptedKey);
}
