import algosdk, { algosToMicroalgos, microalgosToAlgos } from "algosdk";
import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Routes } from "../App";
import { AssetInfo } from "../lib/hooks/useAssetInfo";
import { formatBalance, walletHasSufficientBalance } from "../lib/utils/lib";
import { WalletProvider } from "../lib/wallet/Wallet";
import { WalletStore } from "../store/WalletStore";
import PopupDailog from "./PopupDailog";
import ToolTip from "../assets/tooltip";
import AssetInput from "./AssetInput";
import ReactTooltip from "react-tooltip";
import { balanceOfAssets } from "../lib/asset/Asset";
import { ProofResponse } from "algosdk/dist/types/src/client/v2/algod/models/types";

interface SendAssetProps {
  assets: { [assetId: string]: AssetInfo };
  wallet: WalletProvider;
  onClickCancel?: () => void;
  algoPrice: number;
}

interface AnalyticsWindow extends Window {
  analytics?: any;
}

const SendAsset = ({
  assets,
  wallet,
  onClickCancel,
  algoPrice,
}: SendAssetProps) => {
  const {
    register,
    handleSubmit,
    watch,
    reset: formReset,
    formState: { errors },
    setValue,
    getValues,
    control,
  } = useForm();

  const watchAssetId = useWatch({
    control,
    name: "asset_id",
    defaultValue: "0",
  });

  const watchAmount = useWatch({
    control,
    name: "amount",
    defaultValue: 0,
  });

  // const assetCount = Object.keys(assets).length;
  const availableAlgo = wallet.getAvailableBalance();
  // Number(wallet.getAlgoBalance()) -
  // 1000 -
  // algosToMicroalgos(0.1) * assetCount;

  const hasEnoughAsset =
    watchAssetId === "0"
      ? watchAmount <= availableAlgo
      : watchAmount <= wallet.getAssetBalance(watchAssetId).balance;

  useEffect(() => {
    function isValidAmount(amt: any): any {
      return hasEnoughAsset;
    }
    register("amount", {
      required: true,
      validate: isValidAmount,
    });
  }, [register, getValues, assets, hasEnoughAsset]);

  const history = useHistory();

  const [waitingForTxn, setWaitingForTxn] = useState(false);
  const [hasUserSubmitted, setHasUserSubmitted] = useState(false);
  const [hasUserSend, setHasUserSend] = useState(false);
  const [inputState, setInputState] = useState<any>();

  const onSend = async () => {
    setWaitingForTxn(true);
    let txid = undefined;
    if (inputState.asset_id === "0") {
      // send algos
      txid = await wallet?.sendAlgos(
        inputState.to,
        inputState.amount,
        inputState.note
      );
      wallet?.updateBalance();
    } else {
      // send ASA
      const receiver_assets = await balanceOfAssets(inputState.to);
      const hasAsset = receiver_assets.filter(
        (assetInfo) => assetInfo.assetId === Number(inputState.asset_id)
      );
      if (!hasAsset[0]) {
        setWaitingForTxn(false);
        toast.error("Receiver has not opted-in the token.");
        return;
      }
      const asset_details = assets[inputState.asset_id];
      txid = await wallet?.sendAsset(
        inputState.to,
        inputState.amount,
        inputState.asset_id,
        inputState.note
      );
      wallet?.updateAssetBalances();
    }

    const customWindow: AnalyticsWindow = window;
    if (txid) {
      toast.success("Successfully executed transaction.");
      if (customWindow.analytics) {
        customWindow.analytics.track("send_algos.success", {
          amount: microalgosToAlgos(inputState.amount),
        });
      }
    } else {
      toast.error("Could not execute transaction.");
      if (customWindow.analytics) {
        customWindow.analytics.track("send_algos.failed", {
          amount: microalgosToAlgos(inputState.amount),
        });
      }
    }
    setWaitingForTxn(false);
    formReset();
    WalletStore.update((s) => {
      s.studioState = null;
    });
    history.push(Routes.WALLET);
  };

  const onSubmit = async (props: any) => {
    setInputState(props);
    setHasUserSubmitted(true);
  };

  return (
    <PopupDailog
      title={!hasUserSubmitted ? "Send" : "Confirm"}
      onClose={onClickCancel}
    >
      {!hasUserSubmitted && (
        <form className="mt-2 flex flex-col" onSubmit={handleSubmit(onSubmit)}>
          <label
            htmlFor=""
            className="text-tokodot-primary font-semibold text-base"
          >
            Token
          </label>
          <select
            className="mt-2 bg-tokodot-dark-secondary text-white px-4 py-3 rounded-xl"
            {...register("asset_id", { required: true })}
            name="asset_id"
            onChange={(e) => setValue("asset_id", e.target.value)}
          >
            <option key={"0"} value={"0"}>
              ALGO
            </option>
            {Object.keys(assets)
              .sort()
              .map((assetId) => {
                return (
                  <option key={assetId} value={assetId}>
                    {assetId !== "0" ? assetId + " - " : ""}{" "}
                    {assets[assetId].info.name}
                  </option>
                );
              })}
          </select>
          {errors.asset_id && (
            <p className="mt-1 ml-1 text-sm text-red-500">
              You need to select an asset.
            </p>
          )}
          <label
            htmlFor=""
            className="mt-4 text-tokodot-primary font-semibold text-base"
          >
            Amount
          </label>
          <AssetInput
            initialValue={0}
            decimals={
              watchAssetId === "0"
                ? 6
                : assets[watchAssetId]?.info.decimals.valueOf()
            }
            algoPrice={algoPrice}
            wallet={wallet}
            disabled={false}
            onChange={(val: any) => setValue("amount", val)}
            className="bg-tokodot-dark-secondary text-white px-4 py-3 rounded-xl"
            algoOnly={watchAssetId === "0"}
          />
          {!hasEnoughAsset && (
            <p className="mt-1 ml-1 text-sm text-red-500">
              You do not have sufficient balance.
            </p>
          )}
          {errors.amount && (
            <p className="mt-1 ml-1 text-sm text-red-500">
              You need to enter an valid number.
            </p>
          )}
          <label
            htmlFor=""
            className="mt-4 text-tokodot-primary font-semibold text-base"
          >
            <div className="flex flex-row">
              To
              {/* <span
                className="ml-1 mb-1 mt-0.5 w-4"
                data-tip="The receiver of your tokens"
              >
                <ToolTip />
              </span>
              <ReactTooltip></ReactTooltip> */}
            </div>
          </label>
          <input
            {...register("to", {
              validate: (addr) => algosdk.isValidAddress(addr),
            })}
            placeholder="Enter receipient address"
            className="bg-tokodot-dark-secondary text-white px-4 py-3 rounded-xl"
            name="to"
          />
          {errors.to && (
            <p className="mt-1 ml-1 text-sm text-red-500">
              Enter a valid Algorand address.
            </p>
          )}
          <label
            htmlFor=""
            className="mt-4 text-tokodot-primary font-semibold text-base"
          >
            Note
          </label>
          <textarea
            {...register("note", {
              maxLength: 1000,
              value: "",
              validate: (note) =>
                new Uint8Array(Buffer.from(note, "utf-8")).length <= 1000,
            })}
            className="mt-2 w-full py-2 px-4 text-md font-medium bg-tokodot-dark-secondary text-white rounded-lg"
            onInput={() => {}}
            cols={48}
            style={{ minHeight: "120px", resize: "none" }}
            placeholder="Enter note here"
            name="note"
          />
          {errors.note && (
            <p className="mt-1 ml-1 mb-8 text-sm text-red-500">
              Note cannot be more than 1000 bytes.
            </p>
          )}
          <button
            type="submit"
            className="disabled:opacity-40 mt-4  px-6 py-4  mx-2 text-center text-lg font-black text-white bg-tokodot-primary rounded-lg focus:outline-none flex justify-center items-center"
            disabled={waitingForTxn}
          >
            Confirm
          </button>
          <button
            type="button"
            onClick={onClickCancel}
            className="mt-4  px-6 py-3  mx-2 text-center text-sm font-semibold text-white uppercase focus:outline-none"
          >
            Cancel
          </button>
        </form>
      )}
      {hasUserSubmitted && (
        <div className="w-full flex flex-col">
          <div className="mt-2 w-full flex items-center justify-between px-4 py-3 bg-tokodot-dark-secondary text-white font-black rounded-lg">
            {inputState.asset_id === "0" ? (
              <span>ALGO</span>
            ) : (
              <>
                <span>{assets[inputState.asset_id].info.name}</span>
                <span>ID: {inputState.asset_id}</span>
              </>
            )}
          </div>
          <p className="ml-4 mt-4 font-bold text-xl text-white">
            {inputState.asset_id === "0"
              ? microalgosToAlgos(inputState.amount)
              : formatBalance(
                  Number(assets[inputState.asset_id].info.decimals),
                  inputState.amount
                )}{" "}
            {inputState.asset_id === "0"
              ? "ALGO"
              : assets[inputState.asset_id].info.unitName}
          </p>

          <p className="ml-4 mt-4 font-bold text-xl text-tokodot-primary">to</p>
          <p className="ml-4 mt-4 font-bold text-xl text-white break-all">
            {inputState.to}
          </p>
          <button
            className="w-full mt-4 px-6 py-3 text-xl font-black text-white  bg-tokodot-primary focus:outline-none rounded-lg flex items-center justify-center disabled:opacity-50"
            onClick={() => {
              setHasUserSubmitted(true);
              onSend();
              setHasUserSend(true);
            }}
            disabled={waitingForTxn}
          >
            {waitingForTxn && <Spinner />}
            Send
          </button>
          <button
            className="mt-4 text-red-400 font-semibold text-md py-2 px-6 focus:outline-none rounded-sm"
            onClick={() => {
              setHasUserSubmitted(false);
            }}
            disabled={hasUserSend}
          >
            Cancel
          </button>
        </div>
      )}
    </PopupDailog>
  );
};

const Spinner = () => (
  <svg
    className="animate-spin-fast -ml-1 mr-3 h-5 w-5 text-white"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
);

export default SendAsset;
