// export type TransactionTypeFieldsType =
//   | "payment-transaction"
//   | "keyreg-transaction"
//   | "asset-config-transaction"
//   | "asset-freeze-transaction"
//   | "asset-transfer-transaction";

import { TransactionType } from "algosdk";

export const TransactionTypeAttribute = {
  pay: "payment-transaction",
  keyreg: "keyreg-transaction",
  acfg: "asset-config-transaction",
  axfer: "asset-transfer-transaction",
  afrz: "asset-freeze-transaction",
  appl: "application-transaction",
};

export interface Transaction {
  fee: number;
  id: string;
  note: string;
  sender: string;
  txType: TransactionType;
  "payment-transaction"?: PaymentTransaction;
  "keyreg-transaction"?: KeyRegTransaction;
  "asset-config-transaction"?: AssetConfigTransaction;
  "asset-transfer-transaction"?: AssetTransferTransaction;
  "asset-freeze-transaction"?: AssetFreezeTransaction;
}

export interface PaymentTransaction {
  amount: number;
  closeAmount: number;
  receiver: string;
}
export interface KeyRegTransaction {}
export interface AssetConfigTransaction {}
export interface AssetTransferTransaction {
  amount: number;
  assetId: number;
  closeAmount: number;
  receiver: string;
}
export interface AssetFreezeTransaction {}
