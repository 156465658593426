import algosdk, { algosToMicroalgos, microalgosToAlgos } from "algosdk";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Routes } from "../App";
import { AssetInfo } from "../lib/hooks/useAssetInfo";
import {
  getAlgod,
  reconfigureAlgorandAsset,
  sendTransaction,
  waitTransaction,
} from "../lib/utils/lib";
import { WalletProvider } from "../lib/wallet/Wallet";
import { WalletStore } from "../store/WalletStore";
import FullPageDialog from "./FullPageDialog";
import PopupDailog from "./PopupDailog";

const tabs = ["Configure", "Freeze", "Clawback"] as const;
type Tabs = typeof tabs[number];

const DISPLAY_PROPERTIES = [
  ["id", "token id"],
  ["unitName", "unit name"],
  ["total", "unit name"],
  ["decimals", "decimals"],
  ["creatorAddress", "creator"],
  ["managerAddress", "manager"],
  ["reserveAddress", "reserve"],
  ["freezeAddress", "freeze"],
  ["clawbackAddress", "clawback"],
  ["metadataHash", "meta hash"],
  ["url", "url"],
];

function EditAsset(props: {
  wallet: WalletProvider;
  assets: { [assetId: string]: AssetInfo };
  closeShareForm: () => void;
  selectedAssetId: string;
}) {
  const {
    register,
    handleSubmit,
    watch,
    reset: formReset,
    formState: { errors },
    setValue,
    getValues,
    control,
  } = useForm();

  const history = useHistory();
  const wallet = WalletStore.useState((s) => s.wallet);
  const [manager, setManager] = useState("");
  const [reserve, setReserve] = useState("");
  const [clawback, setClawback] = useState("");
  const [freeze, setFreeze] = useState("");
  const [waitingForTxn, setWaitingForTxn] = useState(false);
  const selectedToken = props.assets[props.selectedAssetId];
  const assetIndex = Number(props.selectedAssetId);
  const [currentTab, setCurrentTab] = useState<Tabs>("Configure");

  const onSubmit = async (props: any) => {
    setManager(props.manager);
    setReserve(props.reserve);
    setClawback(props.clawback);
    setFreeze(props.freeze);

    if (wallet) {
      setWaitingForTxn(true);
      const txn = await reconfigureAlgorandAsset(
        wallet.getAddress(),
        props.manager === "" ? undefined : props.manager,
        props.freeze === "" ? undefined : props.freeze,
        props.clawback === "" ? undefined : props.clawback,
        props.reserve === "" ? undefined : props.reserve,
        assetIndex
      );

      const txnId = txn.txID().toString();
      const signedTxn = wallet.signTx(txn);
      await sendTransaction(getAlgod(), signedTxn);
      const result = await waitTransaction(txnId);
      if (result) {
        toast.success("Success");
      } else {
        toast.error("Failed");
      }
    }
    setWaitingForTxn(false);
    formReset();
    history.push(Routes.WALLET);
  };

  return (
    <FullPageDialog
      title={"Edit Token: " + selectedToken.info.name}
      onClose={props.closeShareForm}
    >
      <div className="grid grid-cols-3 gap-x-2 gap-y-2">
        {DISPLAY_PROPERTIES.map((property) => {
          const propKey = property[0] as keyof typeof selectedToken.info;
          const value = selectedToken.info[propKey];
          if (value === undefined) return null;
          return (
            <div className="flex flex-col">
              <span className="text-xs text-tokodot-gray font-bold uppercase">
                {property[1]}
              </span>
              <span className="text-sm text-white font-medium break-all">
                {value}
              </span>
            </div>
          );
        })}
      </div>
      <div className="mt-6 w-full flex items-center justify-center text-lg font-semibold text-white">
        {tabs.map((tab) => (
          <div
            className={`ml-12 cursor-pointer border-b-2 border-tokodot-primary-hover ${
              currentTab === tab
                ? "border-tokodot-primary"
                : "border-transparent"
            }`}
            onClick={() => setCurrentTab(tab)}
          >
            {tab}
          </div>
        ))}
      </div>
      <form className="mt-6 flex flex-col" onSubmit={handleSubmit(onSubmit)}>
        <label
          htmlFor=""
          className="text-tokodot-primary font-semibold text-base"
        >
          Manager Address
        </label>
        <input
          {...register("manager", {
            required: false,
            validate: (add) => algosdk.isValidAddress(add) || !add,
          })}
          defaultValue={selectedToken.info.managerAddress}
          className="bg-tokodot-dark-secondary text-white text-base mt-1 px-4 py-3 rounded-xl"
          name="manager"
        />
        {errors.manager && (
          <p className="mt-1 ml-1 text-sm text-red-500">
            You need to enter a valid Algorand address.
          </p>
        )}
        <label
          htmlFor=""
          className="mt-4 text-tokodot-primary font-semibold text-base"
        >
          Reserve Address
        </label>
        <input
          {...register("reserve", {
            required: false,
            validate: (add) => algosdk.isValidAddress(add) || !add,
          })}
          defaultValue={selectedToken.info.reserveAddress}
          className="bg-tokodot-dark-secondary text-white text-base mt-1 px-4 py-3 rounded-xl"
          name="reserve"
        />
        {errors.reserve && (
          <p className="mt-1 ml-1 text-sm text-red-500">
            You need to enter a valid Algorand address
          </p>
        )}
        <label
          htmlFor=""
          className="mt-4 text-tokodot-primary font-semibold text-base"
        >
          Clawback Address
        </label>
        <input
          {...register("clawback", {
            required: false,
            validate: (add) => algosdk.isValidAddress(add) || !add,
          })}
          defaultValue={selectedToken.info.clawbackAddress}
          className="bg-tokodot-dark-secondary text-white text-base mt-1 px-4 py-3 rounded-xl"
          name="clawback"
        />
        {errors.clawback && (
          <p className="mt-1 ml-1 text-sm text-red-500">
            You need to enter a valid Algorand address
          </p>
        )}
        <label
          htmlFor=""
          className="mt-4 text-tokodot-primary font-semibold text-base"
        >
          Freeze Address
        </label>
        <input
          {...register("freeze", {
            required: false,
            validate: (add) => algosdk.isValidAddress(add) || !add,
          })}
          defaultValue={selectedToken.info.freezeAddress}
          className="bg-tokodot-dark-secondary text-white text-base mt-1 px-4 py-3 rounded-xl"
          name="freeze"
        />
        {errors.freeze && (
          <p className="mt-1 ml-1 text-sm text-red-500">
            You need to enter a valid Algorand address
          </p>
        )}
        <button
          type="submit"
          className="disabled:opacity-40 mt-10 px-6 py-4 mx-2 text-center text-lg font-black text-white bg-tokodot-primary rounded-lg focus:outline-none flex justify-center items-center"
        >
          {waitingForTxn && <Spinner />}
          Confirm
        </button>
      </form>
    </FullPageDialog>
  );
}

const Spinner = () => (
  <svg
    className="animate-spin-fast -ml-1 mr-3 h-5 w-5 text-white"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
);

export default EditAsset;
