import React from "react";

export const Button = (props: React.PropsWithChildren<any>) => {
  return (
    <button
      className="bg-purple-500 hover:bg-purple-800 text-white text-center font-semibold rounded-md text-lg p-2 px-4"
      {...props}
    >
      {props.children}
    </button>
  );
};

export const WhiteBox = (props: React.PropsWithChildren<any>) => {
  return (
    <div
      className="flex flex-col items-left w-auto h-auto p-8 bg-blue-50 rounded shadow"
      {...props}
    >
      {props.children}
    </div>
  );
};

export const Background = (props: React.PropsWithChildren<any>) => {
  return (
    <div className="w-screen h-screen bg-purple-50" {...props}>
      {props.children}
    </div>
  );
};

export const WhiteCard = (props: React.PropsWithChildren<any>) => {
  return (
    <div
      className="bg-tokodot-dark-primary flex flex-col rounded-lg shadow-xl p-6"
      {...props}
    >
      {props.children}
    </div>
  );
};

export const Circle = (props: React.PropsWithChildren<any>) => {
  return (
    <div
      style={{
        borderRadius: "80%",
        width: "36px",
        height: "36px",
        paddingLeft: "6px",
        paddingRight: "6px",
        textAlign: "center",
        fontWeight: "bolder",
        marginTop: "4px",
      }}
      className="text-white border-4 border-white"
    >
      {props.children}
    </div>
  );
};

export const FiveXFiveGrid = (props: React.PropsWithChildren<any>) => {
  return (
    <div className="grid grid-cols-5 md:grid-cols-5" {...props}>
      {props.children}
    </div>
  );
};

export const NewKeyPhraseInfoText = (props: React.PropsWithChildren<any>) => {
  return (
    <div className="w-auto h-auto" {...props}>
      <h1 className="text-xl font-bold text-black">Copy your new key phrase</h1>
      {props.children}
    </div>
  );
};

interface MnemonicDisplayArgs {
  mnemonic: string;
  bgColor?: string;
  textColor?: string;
}

export const MnemonicDisplay: React.FC<MnemonicDisplayArgs> = ({
  mnemonic,
  bgColor,
  textColor,
}) => {
  const splitKeyToArray: (input: string) => Array<string> = (input: string) => {
    let splitKey = input.trim().split(/\s+/).slice(0, 25);
    // fill remaining with empty string
    for (var i = splitKey.length - 1; i < 24; i++) {
      splitKey.push("");
    }
    return splitKey;
  };
  const keyArray = splitKeyToArray(mnemonic);

  return (
    <div className="grid grid-cols-2 sm:grid-cols-3">
      {keyArray.map((word, i) => (
        <div
          key={i}
          className={`flex overflow-hidden px-5 py-3 mb-2 mr-2 text-sm font-semibold bg-tokodot-dark-secondary text-white space-between ${bgColor} ${textColor} `}
        >
          <p className="select-none w-12 text-center box-content">{i + 1}.</p>
          <p className="flex-grow   whitespace-nowrap m-0">{word + ""}</p>
        </div>
      ))}
    </div>
  );
};
