const CryptoJs = require("crypto-js");
export type EncryptFN = (walletKey: string) => string;

// export function encryptUserPhrase(
//   phrase: string,
//   wallet: MnemonicWallet
// ): string {
//   const encryptFn: EncryptFN = (walletKey: string) => {
//     return CryptoJs.AES.encrypt(walletKey, phrase);
//   };

//   return wallet.encrypt(encryptFn);
// }

export function encryptMnemonicKey(
  walletKey: string,
  phrase: string): string {
    return CryptoJs.AES.encrypt(walletKey, phrase);
};

export function decryptMnemonicKey(
  encryptedAES: string,
  phrase: string
): string {
  const decryptedBytes = CryptoJs.AES.decrypt(encryptedAES, phrase);
  return decryptedBytes.toString(CryptoJs.enc.Utf8);
}
