import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Coinbase1 from "../assets/Coinbase1.png";
import Coinbase2 from "../assets/Coinbase2.png";
import Coinbase3 from "../assets/Coinbase3.png";
import Coinbase4 from "../assets/Coinbase4.png";
import Coinbase5 from "../assets/Coinbase5.png";
import Coinbase6 from "../assets/Coinbase6.png";
import Coinbase7 from "../assets/Coinbase7.png";

const Coinbase = () => {
  return (
    <Carousel showThumbs={false}>
      <div>
        <img src={Coinbase1} alt="Coinbase 1" />
      </div>
      <div>
        <img src={Coinbase2} alt="Coinbase 2" />
      </div>
      <div>
        <img src={Coinbase3} alt="Coinbase 3" />
      </div>
      <div>
        <img src={Coinbase4} alt="Coinbase 4" />
      </div>
      <div>
        <img src={Coinbase5} alt="Coinbase 5" />
      </div>
      <div>
        <img src={Coinbase6} alt="Coinbase 6" />
      </div>
      <div>
        <img src={Coinbase7} alt="Coinbase 7" />
      </div>
    </Carousel>
  );
};

export default Coinbase;
