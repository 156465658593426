import { Redirect } from "react-router-dom";
import { Routes } from "../App";
import CreateWallet from "../components/CreateWallet";
import { tryGetEncryptedKeyFromStorage } from "../lib/utils/localStorage";
import { Circle } from "../components/common";

const CreateWalletPage = () => {
  if (tryGetEncryptedKeyFromStorage()) {
    return <Redirect to={Routes.UNLOCK} />;
  }
  return (
    <div className="container mx-auto w-full px-6">
      <div className="flex mt-12 text-2xl p-12">
        <div className="-ml-14 mt-1">
          <Circle>2</Circle>
        </div>
        <div className="flex flex-col text-tokodot-primary">
          <p className="ml-4 font-bold mt-2 text-white">
            Let’s create a new wallet
          </p>
          <p className="ml-4 mt-3 font-medium text-xl text-tokodot-gray">
            You need to store this secret recovery phrase somewhere safe! You
            will need it for future access to your account. Do not share this
            key with anyone.
          </p>
        </div>
      </div>
      <div className="mt-10">
        <CreateWallet />
      </div>

      <div className="mt-12"></div>
    </div>
  );
};

export default CreateWalletPage;
