import { Asset } from "algosdk/dist/types/src/client/v2/algod/models/types";
import { useEffect, useMemo, useState } from "react";
import { getAssetParams } from "../asset/Asset";
import { AssetParams } from "../asset/types";
import { formatBalance } from "../utils/lib";
import { WalletAssetBalance } from "../wallet/types";

export interface AssetInfo {
  info: AssetParams;
  balance: number | bigint;
  displayBalance: number;
}
export default function useAssetInfo(walletAssets: WalletAssetBalance | null) {
  // object for all asset info
  const [isLoading, setIsLoading] = useState(true);
  const [assetInfo, setAssetInfo] = useState<{ [assetId: string]: AssetInfo }>(
    {}
  );

  // get info on the assets found
  useEffect(() => {
    setIsLoading(true);
    async function getAssetInfo() {
      try {
        const _assetInfo = {} as { [assetId: string]: AssetInfo };
        for (const assetId in walletAssets) {
          const _info = await getAssetParams(assetId);
          if (_info) {
            _assetInfo[assetId] = {
              info: _info,
              balance: walletAssets[assetId].balance,
              displayBalance: formatBalance(
                Number(_info.decimals),
                Number(walletAssets[assetId].balance)
              ),
            };
          }
        }
        setAssetInfo(_assetInfo);
        setIsLoading(false);
      } catch (_) {
        setIsLoading(false);
      }
    }
    getAssetInfo();
  }, [walletAssets]);

  return { isLoading, assetInfo };
}
