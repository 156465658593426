import React, { useEffect } from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import { Routes } from "../App";
import WalletContainer from "../containers/WalletContainer";
import { WalletStore } from "../store/WalletStore";

const ViewWalletPage: React.FC<any> = () => {
  const wallet = WalletStore.useState((s) => s.wallet);
  if (!wallet) {
    return <Redirect to={Routes.UNLOCK} />;
  }
  return (
    <div className="md:container md:mx-auto flex justify-center">
      <div className="mt-12 mb-12 p-3">
        <WalletContainer wallet={wallet} />
      </div>
    </div>
  );
};

const NoWalletFallback: React.FC<any> = () => {
  const history = useHistory();
  useEffect(() => {
    const timeout = setTimeout(() => {
      history.push("/wallet");
    }, 3000);
    return () => clearTimeout(timeout);
  });
  return <div className="">No wallet. Redirecting to wallet page</div>;
};

export default ViewWalletPage;
