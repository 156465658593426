import React from "react";
import { Jazzicon } from "@ukstv/jazzicon-react";
import { getActiveNetwork } from "../../lib/network";
import { AssetInfo } from "../../lib/hooks/useAssetInfo";
import { Link } from "react-router-dom";
import { Routes } from "../../App";

export interface WalletCardProps {
  address: string;
  algos: number;
  algo_price: number;
  rewards: number;
  assets: { [assetId: string]: AssetInfo };
  actions: boolean;
  onClickReceive?: () => void;
  onClickSend?: () => void;
  onClickAddAsset?: () => void;
  onClickBuyAsset?: () => void;
  onClickShare?: (id: any) => void;
  onClickEdit?: (id: any) => void;
  requestFreeAlgos?: () => void;
}

const Header = ({ children }: any) => (
  <div className="w-full flex items-start">{children}</div>
);

const WalletStats = ({ children }: any) => (
  <div className="mt-4 grid grid-cols-1 sm:grid-cols-3">{children}</div>
);

const WalletStatItem = ({ title, subtitle }: any) => (
  <div className="flex flex-col items-center mb-4 sm:mb-0">
    <div className="flex flex-col items-center md:items-end">
      <span className="text-xs font-bold text-tokodot-gray uppercase">
        {subtitle}
      </span>
      <span className="text-md text-white font-medium">{title}</span>
    </div>
  </div>
);

const WalletActions = ({ children }: any) => (
  <div className="mt-6 grid grid-cols-2 sm:grid-cols-4">{children}</div>
);

const WalletActionItem = ({ icon, title, onClick }: any) => (
  <button
    onClick={() => onClick && onClick()}
    className="group flex flex-col items-center focus:outline-none mb-4 sm:mb-0"
  >
    <div className="flex justify-center items-center text-white bg-tokodot-primary p-3 rounded-2xl group-hover:bg-purple-500">
      {icon}
    </div>
    <div className="mt-2 text-xs text-tokodot-gray font-bold">{title}</div>
  </button>
);

const AssetList = ({ children }: any) => (
  <div className="mt-9">
    <div className="w-full flex justify-between font-bold text-md border-b border-gray-500 py-2">
      <div className="w-full flex justify-between text-gray-400">
        <span>Asset</span>
        <span className="pr-6 ">Balance</span>
      </div>
      <span className="w-24 text-gray-400">Action</span>
    </div>
    {children}
  </div>
);

const AssetListItem = ({
  name,
  unit_name,
  id,
  bal,
  last,
  onClickShare,
  onClickEdit,
}: any) => (
  <>
    <div className="w-full flex justify-between py-2">
      <div className="w-full flex justify-between">
        <div className="flex flex-col items-start text-white">
          <span className="font-bold">{name}</span>
          <a
            target="_blank"
            rel="noreferrer"
            href={getActiveNetwork().explorerUrl + "/asset/" + id}
            className="text-tokodot-primary text-xs font-bold"
          >
            ID: {id}
          </a>
        </div>
        <div className="flex flex-col items-end pr-6 text-white font-bold">
          <span>{bal}</span>
          <span className="text-xs font-semibold">{unit_name}</span>
        </div>
      </div>
      <button
        className=" bg-black text-white rounded-md py-2 px-6 font-semibold text-sm"
        onClick={() => onClickShare(id)}
      >
        Share
      </button>
      <button
        className=" bg-black text-white rounded-md py-2 px-6 font-semibold text-sm"
        onClick={() => onClickEdit(id)}
      >
        Edit
      </button>
    </div>
    {!last && <div className="border-b border-gray-500"></div>}
  </>
);

const WalletCard: React.FC<WalletCardProps> = ({
  address,
  algos,
  algo_price,
  rewards,
  assets,
  actions,
  onClickReceive,
  onClickSend,
  onClickAddAsset,
  onClickBuyAsset,
  onClickShare,
  onClickEdit,
  requestFreeAlgos,
}) => (
  <div className="w-full px-8 py-10 bg-tokodot-dark-primary rounded-xl shadow-xl">
    <Header>
      <div className="flex-col break-all  flex-wrap">
        <div className="flex items-center">
          <p className="font-bold text-tokodot-gray text-xs uppercase">
            wallet address
          </p>
          <button
            onClick={() => {
              navigator.clipboard && navigator.clipboard.writeText(address);
            }}
            className="ml-2 flex text-xs items-center text-tokodot-gray hover:text-white  focus:outline-none"
            style={{ paddingBottom: "0.20em" }}
          >
            <CopyIcon />
          </button>
        </div>
        <p className="mt-1 font-semibold text-white text-md">{address}</p>
        {actions && (
          <div className="flex flex-col sm:flex-row mt-1">
            {getActiveNetwork().network === "testnet" && (
              <button
                onClick={requestFreeAlgos}
                className="flex text-sm font-semibold underline items-center text-white hover:text-purple-500 hover:underline focus:outline-none"
              >
                Get free Algos
              </button>
            )}

            {/* <a
            target="_blank"
            rel="noreferrer"
            href={getActiveNetwork().explorerUrl + "/account/" + address}
            className="ml-2 mt-2 sm:ml-6 sm:mt-0 flex text-xs items-center text-tokodot-purple hover:text-purple-500 hover:underline focus:outline-none"
          >
            <ExplorerIcon />
            <span className="ml-1 font-semibold">View in Explorer</span>
          </a> */}
          </div>
        )}
      </div>
    </Header>
    <WalletStats>
      <WalletStatItem title={algos} subtitle={"Algo Balance"} />
      <WalletStatItem
        title={(algos * algo_price).toFixed(2)}
        subtitle={"USD"}
      />
      <WalletStatItem title={rewards} subtitle={"Rewards"} />
    </WalletStats>
    {actions && (
      <WalletActions>
        <WalletActionItem
          icon={<SendIcon />}
          title={"Send"}
          onClick={onClickSend}
        />
        <WalletActionItem
          icon={<ReceiveIcon />}
          title={"Receive"}
          onClick={onClickReceive}
        />
        <WalletActionItem
          icon={<BuyIcon />}
          title={"Transfer"}
          onClick={onClickBuyAsset}
        />
        <WalletActionItem
          icon={<AddAssetIcon />}
          title={"Opt-In"}
          onClick={onClickAddAsset}
        />
      </WalletActions>
    )}
    {/* <AssetList>
      {Object.keys(assets)
        .sort()
        .map((assetId, i) => (
          <div className="" key={i}>
            <AssetListItem
              key={assetId}
              id={assetId}
              name={assets[assetId].info.name}
              unit_name={assets[assetId].info.unitName}
              bal={assets[assetId].displayBalance}
              last={i === Object.keys(assets).length - 1}
              onClickShare={onClickShare}
            />
          </div>
        ))}
      {Object.keys(assets).length === 0 && (
        <div className="font-bold text-gray-500 mt-6">No assets found</div>
      )}
    </AssetList> */}
  </div>
);

const CopyIcon = () => (
  // <svg
  //   xmlns="http://www.w3.org/2000/svg"
  //   width="24"
  //   height="24"
  //   viewBox="0 0 24 24"
  //   fill="none"
  //   stroke="currentColor"
  //   strokeWidth="2"
  //   strokeLinecap="round"
  //   strokeLinejoin="round"
  //   className="w-4"
  // >
  //   <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
  //   <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
  // </svg>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="3"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
    <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
  </svg>
);

const SendIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <line x1="22" y1="2" x2="11" y2="13"></line>
    <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
  </svg>
);

const ReceiveIcon = () => (
  <svg
    width="20"
    viewBox="0 0 512 512"
    fill="white"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="m496 144c-8.837 0-16-7.164-16-16v-82c0-7.72-6.28-14-14-14h-82c-8.837 0-16-7.164-16-16s7.163-16 16-16h82c25.364 0 46 20.636 46 46v82c0 8.836-7.163 16-16 16z" />
      <path d="m16 144c-8.837 0-16-7.164-16-16v-82c0-25.364 20.636-46 46-46h82c8.837 0 16 7.164 16 16s-7.163 16-16 16h-82c-7.72 0-14 6.28-14 14v82c0 8.836-7.163 16-16 16z" />
      <path d="m128 512h-82c-25.364 0-46-20.636-46-46v-82c0-8.836 7.163-16 16-16s16 7.164 16 16v82c0 7.72 6.28 14 14 14h82c8.837 0 16 7.164 16 16s-7.163 16-16 16z" />
      <path d="m466 512h-82c-8.837 0-16-7.164-16-16s7.163-16 16-16h82c7.72 0 14-6.28 14-14v-82c0-8.836 7.163-16 16-16s16 7.164 16 16v82c0 25.364-20.636 46-46 46z" />
      <path d="m194 240h-84c-25.364 0-46-20.636-46-46v-84c0-25.364 20.636-46 46-46h84c25.364 0 46 20.636 46 46v84c0 25.364-20.636 46-46 46zm-84-144c-7.72 0-14 6.28-14 14v84c0 7.72 6.28 14 14 14h84c7.72 0 14-6.28 14-14v-84c0-7.72-6.28-14-14-14z" />
      <path d="m194 448h-84c-25.364 0-46-20.636-46-46v-84c0-25.364 20.636-46 46-46h84c25.364 0 46 20.636 46 46v84c0 25.364-20.636 46-46 46zm-84-144c-7.72 0-14 6.28-14 14v84c0 7.72 6.28 14 14 14h84c7.72 0 14-6.28 14-14v-84c0-7.72-6.28-14-14-14z" />
      <path d="m402 240h-84c-25.364 0-46-20.636-46-46v-84c0-25.364 20.636-46 46-46h84c25.364 0 46 20.636 46 46v84c0 25.364-20.636 46-46 46zm-84-144c-7.72 0-14 6.28-14 14v84c0 7.72 6.28 14 14 14h84c7.72 0 14-6.28 14-14v-84c0-7.72-6.28-14-14-14z" />
      <path d="m422 352h-38v-54c0-14.336-11.663-26-26-26h-60c-14.337 0-26 11.664-26 26v60c0 14.336 11.663 26 26 26h54v38c0 14.336 11.663 26 26 26h44c14.337 0 26-11.664 26-26v-44c0-14.336-11.663-26-26-26zm-118-48h48v48h-48zm112 112h-32v-32h32z" />
      <path d="m160 176h-16c-8.837 0-16-7.164-16-16v-16c0-8.836 7.163-16 16-16h16c8.837 0 16 7.164 16 16v16c0 8.836-7.163 16-16 16z" />
      <path d="m368 176h-16c-8.837 0-16-7.164-16-16v-16c0-8.836 7.163-16 16-16h16c8.837 0 16 7.164 16 16v16c0 8.836-7.163 16-16 16z" />
      <path d="m160 384h-16c-8.837 0-16-7.164-16-16v-16c0-8.836 7.163-16 16-16h16c8.837 0 16 7.164 16 16v16c0 8.836-7.163 16-16 16z" />
    </g>
  </svg>
);

const BuyIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <circle cx="9" cy="21" r="1"></circle>
    <circle cx="20" cy="21" r="1"></circle>
    <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
  </svg>
);
const AddAssetIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
    <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
    <line x1="12" y1="22.08" x2="12" y2="12"></line>
  </svg>
);

const ExplorerIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
    <polyline points="15 3 21 3 21 9"></polyline>
    <line x1="10" y1="14" x2="21" y2="3"></line>
  </svg>
);

export default WalletCard;
