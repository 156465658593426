import algosdk from "algosdk";
import EventEmitter from "events";
import { balanceOf, balanceOfAssets } from "../asset/Asset";
import { getAlgod, sendTransaction, waitTransaction } from "../utils/lib";
import {
  AssetBalance,
  WalletAssetBalance,
  WalletBalance,
  WalletEventArgsType,
  WalletEventType,
  WalletNameType,
} from "./types";

export abstract class WalletProvider {
  abstract type: WalletNameType;

  abstract getAddress(): string;

  protected emitter: EventEmitter = new EventEmitter();

  public on(event: WalletEventType, listener: (...args: any[]) => void): void {
    this.emitter.on(event, listener);
  }

  public off(event: WalletEventType, listener: (...args: any[]) => void): void {
    this.emitter.off(event, listener);
  }

  protected emit(event: WalletEventType, args: WalletEventArgsType): void {
    this.emitter.emit(event, args);
  }

  protected emitBalanceChange(): void {
    this.emit("balanceChanged", this.balance);
  }

  protected emitAssetBalanceChange(): void {
    this.emit("assetBalanceChanged", this.assetBalance);
  }

  public balance: WalletBalance = { algos: 0, algosWithoutPendingRewards: 0 };
  public assetBalance: WalletAssetBalance = {};

  abstract signTx(tx: algosdk.Transaction): Uint8Array;

  async sendAlgos(to: string, microAlgos: number, text?: string): Promise<any> {
    const algod = getAlgod();
    const from = this.getAddress();
    const suggestedParams = await algod.getTransactionParams().do();
    let transactionOptions = {
      from,
      to,
      amount: microAlgos,
      suggestedParams,
      note: new Uint8Array(Buffer.from(text || "", "utf8")),
    };

    let tx = algosdk.makePaymentTxnWithSuggestedParamsFromObject(
      transactionOptions
    );
    let signedTx = this.signTx(tx);
    let txId = tx.txID().toString();
    // send transaction
    await sendTransaction(algod, signedTx);
    // wait for trasaction
    const success = await waitTransaction(txId);
    // update balances
    this.updateBalance();
    return success ? txId : null;
  }

  async sendAsset(
    to: string,
    amount: number,
    assetId: string,
    text?: string
  ): Promise<any> {
    const algod = getAlgod();
    const from = this.getAddress();
    const suggestedParams = await algod.getTransactionParams().do();
    let transactionOptions = {
      from,
      to,
      amount,
      assetIndex: parseInt(assetId),
      suggestedParams,
      note: new Uint8Array(Buffer.from(text || "", "utf8")),
    };
    const tx = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject(
      transactionOptions
    );
    const signedTx = this.signTx(tx);
    let txId = tx.txID().toString();
    // send transaction
    await sendTransaction(algod, signedTx);
    // wait for trasaction
    const success = await waitTransaction(txId);
    // update balances
    this.updateAssetBalances();
    return success ? txId : null;
  }

  public async updateBalance(): Promise<WalletBalance> {
    let newBalance = await balanceOf(this.getAddress());
    const oldString = JSON.stringify(this.balance);
    const newString = JSON.stringify(newBalance);
    if (oldString !== newString) {
      this.balance = newBalance;
      this.emitBalanceChange();
    }
    return newBalance;
  }

  public async updateAssetBalances(): Promise<WalletAssetBalance> {
    let newBalances = await balanceOfAssets(this.getAddress());

    let walletAssetBalance: WalletAssetBalance = {};
    for (const bal of newBalances) {
      const asset: AssetBalance = {
        balance: bal.amount,
        params: bal,
      };
      walletAssetBalance[String(bal.assetId)] = asset;
    }
    const oldString = JSON.stringify(this.assetBalance);
    const newString = JSON.stringify(walletAssetBalance);
    if (oldString !== newString) {
      this.assetBalance = walletAssetBalance;
      this.emitAssetBalanceChange();
    }
    return walletAssetBalance;
  }

  public getAssetBalances(): WalletAssetBalance {
    return this.assetBalance;
  }

  public getAssetBalance(assetId: string): AssetBalance {
    return this.assetBalance[assetId] || 0;
  }

  public getBalance(): WalletBalance {
    return this.balance;
  }

  public getAlgoBalance(): number | bigint {
    return this.balance.algos;
  }

  async getTransactions(): Promise<any> {}

  public getAvailableBalance(): bigint {
    // algo accounts need minimum balance to be active
    const minimumForAccount = 10 ** 5;

    if (this.getAlgoBalance() < minimumForAccount) {
      return BigInt(0);
    }
    // for each asset it increases by 10**5
    const numAssets = Object.keys(this.assetBalance).length;
    const minimumForAssets = numAssets * 10 ** 5;
    return (
      BigInt(this.balance.algos) -
      BigInt(minimumForAssets) -
      BigInt(minimumForAssets)
    );
  }
}
