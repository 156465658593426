import { useEffect, useRef } from "react";
import { WalletEventType } from "../wallet/types";
import { WalletProvider } from "../wallet/Wallet";

export default function useWalletEventListener(
  eventName: WalletEventType,
  handler: any,
  wallet: WalletProvider
) {
  const savedHandler = useRef<any>(null);

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const eventListener = (event: any) => savedHandler.current(event);

    wallet.on(eventName, eventListener);

    return () => {
      wallet.off(eventName, eventListener);
    };
  }, [eventName, wallet]);
}
