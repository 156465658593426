import React, { useCallback, useEffect, useState, useMemo } from "react";
import {
  AssetBalance,
  WalletAssetBalance,
  WalletBalance,
} from "../lib/wallet/types";
import { WalletProvider } from "../lib/wallet/Wallet";
import { getExchangePrice } from "../lib/network";
import { AlgoExchangePrice } from "../lib/network/types";
import algosdk, { microalgosToAlgos } from "algosdk";
import { Transaction } from "../lib/explorer/types";
import { getTransactions } from "../lib/explorer/Explorer";
import WalletCard from "../components/wallet/WalletCard";
import WalletActivity from "../components/wallet/WalletActivity";
import { formatBalance, requestFreeAlgos } from "../lib/utils/lib";
import AddressQRCode from "../components/AddressQRCode";
import SendAsset from "../components/SendAsset";
import AddAsset from "../components/AddAsset";
import useWalletEventListener from "../lib/hooks/useEventListener";
import useToggle from "../lib/hooks/useToggle";
import useAssetInfo from "../lib/hooks/useAssetInfo";
import { WalletStore } from "../store/WalletStore";
import { Routes } from "../App";
import {
  Redirect,
  Route,
  useHistory,
  useLocation,
  useRouteMatch,
  withRouter,
} from "react-router-dom";
import BuyAsset from "../components/BuyAsset";

import ShareAsset from "../components/ShareAsset";
import ClaimDrop from "../components/ClaimDrop";
import { MnemonicWallet } from "../lib/wallet/MnemonicWallet";
import { getAllRevDrops } from "../lib/drop/createDrop";
import WalletAssets from "../components/wallet/WalletAssets";
import EditAsset from "../components/EditAsset";

export interface WalletContainerProps {
  wallet: WalletProvider;
}

interface CustomWindow extends Window {
  analytics?: any;
}

const WalletContainer: React.FC<WalletContainerProps> = ({ wallet }) => {
  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const studioState = WalletStore.useState((s) => s.studioState);
  const [shareAssetId, setShareAssetId] = useState<any>(null);
  const [editAssetId, setEditAssetId] = useState<any>(null);

  const [identifiedUser, setIdentifiedUser] = useState<boolean>(false);

  const customWindow: CustomWindow = window;

  useEffect(() => {
    if (!identifiedUser && customWindow.analytics && wallet) {
      customWindow.analytics.identify(wallet.getAddress(), {});
      setIdentifiedUser(true);
    }
  }, []);

  // algo exchange price
  const [algoPrice, setAlgoPrice] = useState<AlgoExchangePrice | null>();

  useEffect(() => {
    async function getExchangeRate() {
      const price = await getExchangePrice();
      setAlgoPrice(price);
    }
    const interval = setInterval(getExchangeRate, 5000);
    return () => clearInterval(interval);
  }, []);

  // get transactions
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  useEffect(() => {
    async function getAllTransactions() {
      const txns = await getTransactions(wallet.getAddress());
      setTransactions(txns);
    }
    getAllTransactions();
  }, [setTransactions, wallet]);

  // wallet balance and asset balance entities
  const [balance, setBalance] = useState<WalletBalance>(() => {
    return wallet.getBalance();
  });
  const [assetBalance, setAssetBalance] = useState<WalletAssetBalance>(() => {
    return wallet.getAssetBalances();
  });

  const { isLoading: isLoadingAssetInfo, assetInfo } = useAssetInfo(
    assetBalance
  );

  const balanceChangeHandler = useCallback(
    (walletBalance) => {
      async function getAllTransactions() {
        const txns = await getTransactions(wallet.getAddress());
        setTransactions(txns);
      }
      setBalance(walletBalance);
      getAllTransactions();
    },
    [setBalance, wallet]
  );
  const assetBalanceChangeHandler = useCallback(
    (assetBalances) => {
      async function getAllTransactions() {
        const txns = await getTransactions(wallet.getAddress());
        setTransactions(txns);
      }
      setAssetBalance(assetBalances);
      getAllTransactions();
    },
    [setAssetBalance, wallet]
  );

  useWalletEventListener("balanceChanged", balanceChangeHandler, wallet);
  useWalletEventListener(
    "assetBalanceChanged",
    assetBalanceChangeHandler,
    wallet
  );

  useEffect(() => {
    async function update() {
      wallet.updateBalance();
      wallet.updateAssetBalances();
    }
    const interval = setInterval(update, 5000);
    return () => clearInterval(interval);
  }, [wallet]);

  const transaction_fields = transactions
    .filter((txn) => {
      return (
        txn["payment-transaction"] ||
        (txn["asset-transfer-transaction"] &&
          txn["asset-transfer-transaction"].amount !== 0)
      );
    })
    .map((txn) => {
      const receiver = txn["asset-transfer-transaction"]
        ? txn["asset-transfer-transaction"].receiver
        : txn["payment-transaction"]
        ? txn["payment-transaction"].receiver
        : "";
      const amount = txn["asset-transfer-transaction"]
        ? txn["asset-transfer-transaction"].amount
        : txn["payment-transaction"]
        ? txn["payment-transaction"].amount
        : 0;
      const assetId = txn["asset-transfer-transaction"]
        ? txn["asset-transfer-transaction"].assetId
        : 0;
      const algo = {
        name: "Algos",
        unit_name: "ALGO",
        amount: microalgosToAlgos(amount),
      };
      const info = assetInfo[String(assetId)];
      const assetBalance = info
        ? formatBalance(Number(info?.info.decimals), Number(amount))
        : 0;
      const asset =
        assetId === 0
          ? algo
          : {
              name: info?.info.name || "",
              unit_name: info?.info.unitName || "",
              amount: assetBalance,
            };
      return {
        txid: txn.id,
        asset,
        from: txn.sender,
        to: receiver,
      };
    });

  const algoBalance = Number(balance?.algos) || 0;
  const rewards = algoBalance
    ? algoBalance - Number(balance?.algosWithoutPendingRewards)
    : 0;

  useEffect(() => {
    const redirectToStudio = async () => {
      const walletBalance = await wallet.updateBalance();
      const bal = microalgosToAlgos(Number(walletBalance.algos));

      if (bal > 0.2 && studioState) {
        history.push(Routes.STUDIO);
      }
    };
    redirectToStudio();
  }, []);

  // disable background scrolling when popup dialog is visible
  useEffect(() => {
    if (location.pathname !== Routes.WALLET) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [location]);

  return (
    <div className="grid grid-cols-1 px-6">
      <div className="col-span-1">
        <ClaimDrop wallet={wallet} />
      </div>
      {/* <p className="text-lg mb-2 text-tokodot-primary font-black">Wallet</p> */}
      <WalletCard
        address={wallet.getAddress()}
        algo_price={algoPrice?.amount ? Number(algoPrice?.amount) : 0}
        algos={microalgosToAlgos(algoBalance)}
        assets={assetInfo}
        rewards={microalgosToAlgos(rewards)}
        actions={true}
        onClickReceive={() => {
          history.push("/wallet/receive");
        }}
        onClickSend={() => {
          history.push("/wallet/send");
        }}
        onClickAddAsset={() => {
          history.push("/wallet/add");
        }}
        onClickBuyAsset={() => {
          history.push("/wallet/buy");
        }}
        onClickShare={(id) => {
          setShareAssetId(id);
          history.push("/wallet/share");
        }}
        onClickEdit={(id) => {
          setEditAssetId(id);
          history.push("/wallet/edit");
        }}
        requestFreeAlgos={() => {
          requestFreeAlgos(wallet.getAddress());
        }}
      />
      <div className="mt-12">
        <div
          className="flex items-center rounded-xl text-white py-3 px-8 shadow-xl transform hover:scale-110 motion-reduce:tranform-none cursor-pointer" // hover:text-black"
          style={{
            backgroundImage: "linear-gradient(165deg, #3C95DA, #704DDF)",
          }}
          onClick={() => {
            history.push(Routes.STUDIO);
          }}
        >
          <Plus />
          <div className="flex items-center ml-6">
            <span className="text-2xl font-bold">Create a Token</span>
            <span className="text-base ml-4 font-medium">
              Build, share, and see all your tokens in one place
            </span>
          </div>
        </div>
      </div>

      <div className="mt-12">
        <p className="font-black text-2xl mb-2 text-tokodot-primary">Tokens</p>
        <WalletAssets
          assets={assetInfo}
          walletAddress={wallet.getAddress()}
          onClickShare={(id) => {
            setShareAssetId(id);
            history.push("/wallet/share");
          }}
          onClickEdit={(id) => {
            setEditAssetId(id);
            history.push("/wallet/edit");
          }}
          isLoadingAssetInfo={isLoadingAssetInfo}
          actions
        />
      </div>
      <div className="mt-12">
        <p className="font-black text-2xl mb-2 text-tokodot-primary">
          Recent Activity
        </p>
        <WalletActivity
          transactions={transaction_fields}
          address={wallet.getAddress()}
        />
      </div>
      <Route path={`${match.path}/receive`}>
        <AddressQRCode wallet={wallet} onClose={() => history.goBack()} />
      </Route>
      <Route path={`${match.path}/send`}>
        <SendAsset
          assets={assetInfo}
          wallet={wallet}
          onClickCancel={() => history.goBack()}
          algoPrice={algoPrice?.amount ? Number(algoPrice?.amount) : 1}
        />
      </Route>
      <Route path={`${match.path}/add`}>
        <AddAsset
          assets={assetInfo}
          wallet={wallet}
          onClickCancel={() => history.goBack()}
        />
      </Route>
      <Route path={`${match.path}/buy`}>
        <BuyAsset />
      </Route>
      <Route path={`${match.path}/share`}>
        <ShareAsset
          wallet={wallet}
          assets={assetInfo}
          closeShareForm={() => history.goBack()}
          selectedAssetId={shareAssetId}
          algoPrice={algoPrice?.amount ? Number(algoPrice?.amount) : 0}
        ></ShareAsset>
      </Route>
      <Route path={`${match.path}/edit`}>
        <EditAsset
          wallet={wallet}
          assets={assetInfo}
          closeShareForm={() => history.goBack()}
          selectedAssetId={editAssetId}
        ></EditAsset>
      </Route>
    </div>
  );
};

const Spinner = () => (
  <svg
    className="animate-spin-fast -ml-1 mr-3 h-5 w-5 text-white"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
);

const Plus = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <line x1="12" y1="5" x2="12" y2="19"></line>
    <line x1="5" y1="12" x2="19" y2="12"></line>
  </svg>
);
export default WalletContainer;
