import algosdk, { microalgosToAlgos } from "algosdk";
import { toast } from "react-toastify";
import internal from "stream";
import { WalletStore } from "../../store/WalletStore";
import { AssetInfo } from "../hooks/useAssetInfo";
import { getActiveNetwork } from "../network";
import { WalletProvider } from "../wallet/Wallet";

export function getAlgod(): algosdk.Algodv2 {
  const algod: algosdk.Algodv2 = new algosdk.Algodv2(
    {},
    getActiveNetwork().algodUrl,
    "",
    {}
  );
  return algod;
}

export function getIndexer(): algosdk.Indexer {
  const indexer: algosdk.Indexer = new algosdk.Indexer(
    {},
    getActiveNetwork().indexerUrl,
    "",
    {}
  );
  return indexer;
}

export async function createAlgorandAsset(
  addr: string,
  assetName: string,
  unitName: string,
  decimal: number,
  total: number,
  url: string | undefined,
  hash: Uint8Array | undefined,
  managerAddress: string | undefined,
  freezeAddress: string | undefined,
  frozen: boolean,
  clawbackAddress: string | undefined,
  reserveAddress: string | undefined
) {
  const client = getAlgod();
  const suggestedParams = await client.getTransactionParams().do();
  let fee = 10; // the number of microAlgos per byte to pay as a transaction fee
  let defaultFrozen = frozen; // whether user accounts will need to be unfrozen before transacting
  let totalIssuance = total; // total number of this asset in circulation
  let decimals = decimal; // hint that the units of this asset are whole-integer amounts
  let reserve = reserveAddress; // specified address is considered the asset reserve (it has no special privileges, this is only informational)
  let freeze = freezeAddress; // specified address can freeze or unfreeze user asset holdings
  let clawback = clawbackAddress; // specified address can revoke user asset holdings and send them to other addresses
  let manager = managerAddress; // specified address can change reserve, freeze, clawback, and manager
  let note = undefined; // arbitrary data to be stored in the transaction; here, none is stored
  let assetURL = url; // optional string pointing to a URL relating to the asset
  let assetMetadataHash = hash; // optional hash commitment of some sort relating to the asset. 32 character length.

  // signing and sending "txn" allows "addr" to create an asset
  let transactionOptions = {
    from: addr,
    decimals,
    defaultFrozen,
    total: totalIssuance,
    assetName,
    unitName,
    assetURL,
    clawback,
    freeze,
    manager,
    note,
    rekeyTo: undefined,
    reserve,
    assetMetadataHash,
    suggestedParams,
  };
  let txn = algosdk.makeAssetCreateTxnWithSuggestedParamsFromObject(
    transactionOptions
  );
  return txn;
}

export async function reconfigureAlgorandAsset(
  addr: string,
  managerAddress: string | undefined,
  freezeAddress: string | undefined,
  clawbackAddress: string | undefined,
  reserveAddress: string | undefined,
  assetIndex: number
) {
  const client = getAlgod();
  const suggestedParams = await client.getTransactionParams().do();
  let reserve = reserveAddress; // specified address is considered the asset reserve (it has no special privileges, this is only informational)
  let freeze = freezeAddress; // specified address can freeze or unfreeze user asset holdings
  let clawback = clawbackAddress; // specified address can revoke user asset holdings and send them to other addresses
  let manager = managerAddress; // specified address can change reserve, freeze, clawback, and manager
  let index = assetIndex;

  // signing and sending "txn" allows "addr" to reconfigure an asset
  const transactionOptions = {
    from: addr,
    freeze: freeze,
    manager: manager,
    clawback: clawback,
    reserve: reserve,
    assetIndex: index,
    suggestedParams,
    strictEmptyAddressChecking: true,
  };
  console.log(transactionOptions);
  const txn = algosdk.makeAssetConfigTxnWithSuggestedParamsFromObject(
    transactionOptions
  );
  return txn;
}

export function signTransaction(sk: any, txn: any) {
  var signedTxn = algosdk.signTransaction(txn, sk);
  return signedTxn;
}

export async function sendTransaction(client: algosdk.Algodv2, signedTxn: any) {
  //submit the transaction
  (async () => {
    let tx = await client.sendRawTransaction(signedTxn).do();
    console.log(tx);
  })().catch((e) => {
    console.log(e.error);
  });
}

export async function waitTransaction(txid: string) {
  let tries = 0;
  let status = await getAlgod().status().do();
  let lastround = status["last-round"];
  while (true) {
    try {
      const pendingInfo = await getAlgod()
        .pendingTransactionInformation(txid)
        .do();
      if (
        "confirmed-round" in pendingInfo &&
        pendingInfo["confirmed-round"] > 0
      ) {
        return true;
      }
      if ("pool-error" in pendingInfo && pendingInfo["pool-error"] !== "") {
        toast.error("Transaction failed");
        return false;
      }
    } catch (e) {
    } finally {
      await getAlgod().statusAfterBlock(lastround).do();
      await new Promise((r) => setTimeout(r, 500));
      tries += 1;
      if (tries > 100) break;
    }
  }
  return false;
}

export function formatBalance(decimals: number, amount: number) {
  if (decimals > 0) {
    return amount / 10 ** decimals;
  } else return amount;
}

const WALLET_STORAGE_KEY = "tokodot:wallet:key";

export function saveEncryptedKey(encryptedKey: string) {
  window.localStorage.setItem(WALLET_STORAGE_KEY, encryptedKey);
}

export function checkForExistingWallet(): boolean {
  const key = window.localStorage.getItem(WALLET_STORAGE_KEY);
  if (key) {
    return true;
  }
  return false;
}

export async function requestFreeAlgos(address: string): Promise<any> {
  try {
    const res = await fetch("/api/creditAccount?receiver=" + address);
    toast.success("Successfully got free Algos. Wait for balance to update.");
  } catch (e) {
    toast.error("Failed to get free Algos. Try again later.");
  }
}

// props.assets
// Object.keys(props.assets)
// props.assets[selectedToken].info.decimals
// min = 0.1 + (0.1 * numAssets) + (0.001 * numTxns);
// transcation = 0.001
// 0.1 at all time

type walletAction = "send" | "share" | "create";
export function walletHasSufficientBalance(
  wallet: WalletProvider | undefined,
  action: walletAction,
  numAssets: number
) {
  if (!wallet) {
    return false;
  }

  const algoBalance = algosdk.microalgosToAlgos(
    Number(wallet.getAlgoBalance())
  );
  const minimumBalance = 0.1 + 0.1 * numAssets;
  const txnFee = 0.001;

  if (action == "send") {
    return algoBalance > txnFee;
  } else if (action == "share") {
    return algoBalance > minimumBalance + txnFee;
  } else if (action == "create") {
    return numAssets <= 1000 && algoBalance > minimumBalance + txnFee;
  }
}
