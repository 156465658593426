import { REFUSED } from "dns";
import { Redirect } from "react-router-dom";
import { Routes } from "../App";
import LockWallet from "../components/LockWallet";
import { tryGetEncryptedKeyFromStorage } from "../lib/utils/localStorage";
import { WalletStore } from "../store/WalletStore";

const LockWalletPage = (props: any) => {
  const walleyKey = WalletStore.useState((s) => s.mnemonicKey);

  if (!walleyKey) {
    return <Redirect to={Routes.NEW} />;
  }

  if (tryGetEncryptedKeyFromStorage()) {
    return <Redirect to={Routes.UNLOCK} />;
  }

  return (
    <div className="container mx-auto w-full ">
      <div className="flex mt-12 text-2xl p-12">
        <div className="flex flex-col">
          <p className="ml-4 font-bold mt-2 text-white">
            Let’s take an additional step to lock your wallet for security
          </p>
        </div>
      </div>
      <div className="mt-12 md:mt-16 flex items-center justify-center px-2">
        <LockWallet />
      </div>
      <div className="mt-24"></div>
    </div>
  );
};

export default LockWalletPage;
