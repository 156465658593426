import React, { useState } from "react";
import { WalletStore } from "../store/WalletStore";
import { WhiteCard } from "./common";
import { Redirect, useHistory } from "react-router-dom";
import { MnemonicWallet } from "../lib/wallet/MnemonicWallet";
import { decryptMnemonicKey } from "../lib/utils/crypto";
import {
  tryGetEncryptedKeyFromStorage,
  WALLET_STORAGE_KEY,
} from "../lib/utils/localStorage";
import { Routes } from "../App";
import { toast } from "react-toastify";

const UnlockWallet = (props: any) => {
  const [userPhrase, setUserPhrase] = useState("");
  const [numTries, setNumTries] = useState(0);

  const history = useHistory();

  const checkUserPhrase = () => {
    const encryptedKey = tryGetEncryptedKeyFromStorage();
    if (!encryptedKey) {
      history.replace(Routes.NEW);
    } else {
      try {
        const decryptKey = decryptMnemonicKey(encryptedKey, userPhrase);
        const wallet = MnemonicWallet.fromMnemonic(decryptKey);
        WalletStore.update((s) => {
          s.wallet = wallet;
        });
        history.push(Routes.WALLET);
      } catch (e) {
        toast.error(
          "Incorrect Password. You have " +
            (numTries + 2) +
            " out of 3 tries left."
        );
        if (numTries >= 2) {
          localStorage.removeItem(WALLET_STORAGE_KEY);
          history.push(Routes.NEW);
        }
        setNumTries(numTries + 1);
      }
    }
  };

  const resetWallet = () => {
    WalletStore.update((s) => {
      s.wallet = undefined;
      s.mnemonicKey = "";
    });
    localStorage.removeItem(WALLET_STORAGE_KEY);
    history.push(Routes.NEW);
  };

  return (
    <WhiteCard>
      <div className="w-full sm:w-128">
        <div className="flex items-start mt-2 text-white text-lg">
          <LockIcon />
          <p className="font-semibold ml-2">
            Enter a password to unlock this wallet
          </p>
        </div>
        <form
          className="mt-12"
          onSubmit={(e) => {
            e.preventDefault();
            checkUserPhrase();
          }}
        >
          <input
            className=" bg-tokodot-dark-secondary text-lg rounded-lg px-6 py-3 mb-2 focus:outline-none focus:border-purple-400 placeholder-gray-500 text-white"
            type="password"
            placeholder="Enter password"
            onChange={(e) => setUserPhrase(e.target.value)}
          ></input>
          <div className="w-full flex flex-row mt-8 items-center justify-between">
            <div className="">
              <button
                type="submit"
                className="bg-tokodot-primary text-white  font-semibold rounded-lg text-lg py-3 px-8"
              >
                Unlock Wallet
              </button>

              <button
                type="reset"
                className="ml-4 text-tokodot-dark-primary bg-white font-semibold rounded-lg text-lg py-3 px-8  focus:outline-none"
                onClick={() => resetWallet()}
              >
                Remove Wallet
              </button>
            </div>
          </div>
        </form>
      </div>
    </WhiteCard>
  );
};
const LockIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
    <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
  </svg>
);
const BookOpenIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"></path>
    <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"></path>
  </svg>
);

export default UnlockWallet;
