import algosdk, { algosToMicroalgos, microalgosToAlgos } from "algosdk";
import { getAssetParams } from "../asset/Asset";
import { MnemonicWallet } from "../wallet/MnemonicWallet";
import { WalletProvider } from "../wallet/Wallet";

export const TOKODOT_DROPS_LIST = "tokodot:drops:list";
export const TOKODOT_DROPS_RECEIVED_LIST = "tokodot:drops_received:list";

export interface DropArguments {
  algo: number;
  assets: {
    [assetId: string]: {
      amount: number;
      decimals: number;
    };
  };
}

export interface DropStorageType {
  [dropKey: string]: DropArguments;
}

export function checkQueryForDrop(query: URLSearchParams) {
  const drop = query.get("drop");
  if (drop) {
    const all_drops = getAllRevDrops();
    all_drops[decodeURIComponent(drop)] = {
      algo: 0,
      assets: {},
    };
    saveAllRevDrops(all_drops);
  }
}

function getAllDrops(): DropStorageType {
  let storageValue: string | null = localStorage.getItem(TOKODOT_DROPS_LIST);
  let all_drops: DropStorageType;
  if (storageValue) {
    all_drops = JSON.parse(storageValue);
  } else {
    all_drops = {};
  }
  return all_drops;
}

function saveAllDrops(all_drops: DropStorageType) {
  localStorage.setItem(TOKODOT_DROPS_LIST, JSON.stringify(all_drops));
}

export function getAllRevDrops(): DropStorageType {
  let storageValue: string | null = localStorage.getItem(
    TOKODOT_DROPS_RECEIVED_LIST
  );
  let all_drops: DropStorageType;
  if (storageValue) {
    all_drops = JSON.parse(storageValue);
  } else {
    all_drops = {};
  }
  return all_drops;
}

export function saveAllRevDrops(all_drops: DropStorageType) {
  localStorage.setItem(TOKODOT_DROPS_RECEIVED_LIST, JSON.stringify(all_drops));
}

export async function generateDropAccount(
  wallet: WalletProvider,
  algos: number
) {
  const newAccount = algosdk.generateAccount();
  const success = await wallet.sendAlgos(newAccount.addr, algos);
  if (success) {
    const all_drops = getAllDrops();
    const drop_key = Buffer.from(newAccount.sk).toString("base64");
    if (!all_drops[drop_key]) {
      all_drops[drop_key] = {
        algo: algos,
        assets: {},
      };
    }
    saveAllDrops(all_drops);
    return newAccount;
  }
  return null;
}

export async function optInAssetToDrop(
  account: algosdk.Account,
  assetId: string,
  assetDecimals: number
) {
  const drop_mnenomic = algosdk.secretKeyToMnemonic(account.sk);
  const dropWallet = MnemonicWallet.fromMnemonic(drop_mnenomic);
  const success = await dropWallet.sendAsset(account.addr, 0, assetId);
  return success;
}

export async function addAssetToDrop(
  wallet: WalletProvider,
  account: algosdk.Account,
  assetId: string,
  assetAmount: number,
  assetDecimals: number
) {
  const address = account.addr;
  const success = await wallet.sendAsset(address, assetAmount, assetId);

  if (success) {
    const all_drops = getAllDrops();
    const key = Buffer.from(account.sk).toString("base64");
    if (!all_drops[key]) {
      all_drops[key] = {
        algo: 0,
        assets: {},
      };
    }

    all_drops[key].assets[assetId] = {
      amount: assetAmount,
      decimals: assetDecimals,
    };
    saveAllDrops(all_drops);
    return all_drops;
  }
  return null;
}
// create new wallet
// send money to new wallet
// encode new wallet

export async function getArgsFromDrop(dropId: string) {
  const dropSk = Buffer.from(dropId, "base64");
  const dropMnenomic = algosdk.secretKeyToMnemonic(dropSk);
  const dropWallet = MnemonicWallet.fromMnemonic(dropMnenomic);
  await dropWallet.updateBalance();
  await dropWallet.updateAssetBalances();

  const reqMicroAlgosForDropTxns = algosdk.algosToMicroalgos(0.001) * 3;
  const availableToClaim =
    Number(dropWallet.getAlgoBalance()) -
    reqMicroAlgosForDropTxns -
    algosToMicroalgos(0.2);

  const assets = Object.keys(dropWallet.getAssetBalances()).map((assetId) => {
    return dropWallet.getAssetBalance(assetId);
  });

  if (availableToClaim < 0) {
    return null;
  }

  return {
    algos: availableToClaim,
    assets: assets,
  };
}

export async function claimDrop(dropId: string, wallet: WalletProvider) {
  const dropSk = Buffer.from(dropId, "base64");
  const dropMnenomic = algosdk.secretKeyToMnemonic(dropSk);
  const dropWallet = MnemonicWallet.fromMnemonic(dropMnenomic);
  await dropWallet.updateBalance();
  await dropWallet.updateAssetBalances();

  // find Algos in drop wallet
  const walletBalance = dropWallet.getAlgoBalance();
  const reqMicroAlgosForDropTxns = algosdk.algosToMicroalgos(0.001) * 3;
  const availableToClaim =
    Number(walletBalance) - reqMicroAlgosForDropTxns - algosToMicroalgos(0.2);
  // send Algos
  await dropWallet.sendAlgos(wallet.getAddress(), availableToClaim);

  // look for assets in drop walelt
  const assetBalance = dropWallet.getAssetBalances();
  const assetId =
    Object.keys(assetBalance).length == 0 ? null : Object.keys(assetBalance)[0];
  if (assetId) {
    const assetInfo = await getAssetParams(assetId);
    if (assetInfo) {
      // opt in
      await wallet.sendAsset(wallet.getAddress(), 0, assetId);
      // get assets
      await dropWallet.sendAsset(
        wallet.getAddress(),
        Number(assetBalance[assetId].balance),
        assetId
      );
      return true;
    }
  }
}

// decode new wallet
// check balance of new wallet
// send money to my wallet
