import { Jazzicon } from "@ukstv/jazzicon-react";
import { Link } from "react-router-dom";
import { getActiveNetwork } from "../../lib/network";

export interface WalletActivityProps {
  transactions: Array<{
    txid: string;
    asset: {
      name: string;
      unit_name: string;
      amount: number;
    };
    from: string;
    to: string;
  }>;
  address: string;
}

const ActivityList = ({ children }: any) => (
  <div className="flex flex-col">{children}</div>
);

const ActivityListItem = ({
  from,
  to,
  address,
  assetName,
  amount,
  unitName,
  id,
}: any) => {
  const sent = from === address;
  const selfTxn = from === address && to === address;
  return (
    <tr className="table-row mb-6 items-center text-md font-medium text-white h-16">
      <td>
        <a
          rel="noreferrer"
          target="_blank"
          href={getActiveNetwork().explorerUrl + "/transaction/" + id}
          className={`flex items-center justify-center bg-tokodot-dark-secondary rounded-xl p-2 ${
            sent ? "text-red-400" : "text-green-400"
          }`}
        >
          {from === to ? (
            <ToSelfIcon />
          ) : sent ? (
            <SentIcon />
          ) : (
            <ReceivedIcon />
          )}
        </a>
      </td>
      {/* <td>
        <Link to={"/account/" + from}>
          <span className="ml-4">{sent ? "You" : from.substring(0, 8)}</span>
        </Link>
      </td> */}
      <td>
        <span className="ml-4">{sent ? "Sent" : "Received"}</span>
      </td>
      <td>
        <span className="ml-4">{amount}</span>
      </td>
      <td>
        <span className="ml-4">
          {unitName || (
            <div className="h-6 bg-tokodot-dark-secondary rounded w-full animate-pulse mb-6"></div>
          )}
        </span>
      </td>
      <td>
        <span className="ml-4">{sent ? "to" : "from"}</span>
      </td>

      <td>
        <Link to={"/account/" + (sent ? to : from)}>
          <span className="ml-4 ">
            {selfTxn && "Self"}
            {!selfTxn && (sent ? to.substring(0, 8) : from.substring(0, 8))}
          </span>
        </Link>
      </td>

      {/* <div
          className={` bg-tokodot-dark-secondary rounded-full p-4 ${
            sent ? "text-red-400" : "text-green-400"
          }`}
        >
          {from === to ? (
            <ToSelfIcon />
          ) : sent ? (
            <SentIcon />
          ) : (
            <ReceivedIcon />
          )}
        </div> */}
      {/* <div className="w-full flex flex-col ml-4">
        <div className="w-full flex justify-between items-end">
          <span className="font-semibold text-md text-white">
            {sent ? "Sent" : "Received"} {assetName}
          </span>
          <span
            className={`text-sm font-bold  text-right ${
              sent ? "text-red-400" : "text-green-400"
            }`}
          >
            {sent ? "-" : "+"}
            {amount} {unitName}
          </span>
        </div>
        <a
          target="_blank"
          rel="noreferrer"
          href={
            getActiveNetwork().explorerUrl + "/account/" + (sent ? to : from)
          }
          className="w-full font-semibold text-sm text-tokodot-primary break-all"
        >
          {sent ? "To" : "From"}:{" "}
          {sent ? to.substring(0, 8) : from.substring(0, 8)}
        </a>
      </div> */}
    </tr>
  );
};

const WalletActivity: React.FC<WalletActivityProps> = ({
  transactions,
  address,
}) => {
  const txns = transactions.filter((txn) => true).slice(0, 8);

  return (
    <div className="tokens-scroll w-full overflow-x-scroll flex flex-col px-8 py-6 bg-tokodot-dark-primary rounded-xl shadow-xl">
      <ActivityList>
        <table className="table-auto">
          <tbody>
            {txns.map((txn) => {
              return (
                <ActivityListItem
                  key={txn.txid}
                  from={txn.from}
                  to={txn.to}
                  id={txn.txid}
                  address={address}
                  assetName={txn.asset.name}
                  amount={txn.asset.amount}
                  unitName={txn.asset.unit_name}
                />
              );
            })}
          </tbody>
        </table>
        {txns.length === 0 && (
          <div className="font-bold text-gray-500 ml-2">
            No transactions found
          </div>
        )}
      </ActivityList>
      <a
        target="_blank"
        rel="noreferrer"
        href={getActiveNetwork().explorerUrl + "/account/" + address}
        className="mt-4 px-6 py-4  mx-2 text-center text-sm font-semibold text-white uppercase bg-tokodot-primary rounded-lg"
      >
        See All Transactions
      </a>
    </div>
  );
};

const SentIcon = () => (
  // <svg
  //   xmlns="http://www.w3.org/2000/svg"
  //   width="24"
  //   height="24"
  //   viewBox="0 0 24 24"
  //   fill="none"
  //   stroke="currentColor"
  //   strokeWidth="2"
  //   strokeLinecap="round"
  //   strokeLinejoin="round"
  // >
  //   <polyline points="14 9 9 4 4 9"></polyline>
  //   <path d="M20 20h-7a4 4 0 0 1-4-4V4"></path>
  // </svg>

  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
    <polyline points="17 8 12 3 7 8"></polyline>
    <line x1="12" y1="3" x2="12" y2="15"></line>
  </svg>
);

const ReceivedIcon = () => (
  // <svg
  //   xmlns="http://www.w3.org/2000/svg"
  //   width="24"
  //   height="24"
  //   viewBox="0 0 24 24"
  //   fill="none"
  //   stroke="currentColor"
  //   strokeWidth="2"
  //   strokeLinecap="round"
  //   strokeLinejoin="round"
  // >
  //   <polyline points="15 10 20 15 15 20"></polyline>
  //   <path d="M4 4v7a4 4 0 0 0 4 4h12"></path>
  // </svg>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
    <polyline points="7 10 12 15 17 10"></polyline>
    <line x1="12" y1="15" x2="12" y2="3"></line>
  </svg>
);

const ToSelfIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <polyline points="1 4 1 10 7 10"></polyline>
    <polyline points="23 20 23 14 17 14"></polyline>
    <path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path>
  </svg>
);

export default WalletActivity;
