import React, { useCallback, useEffect, useState } from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import { Routes } from "../App";
import { WhiteCard } from "../components/common";
import WalletDetails from "../components/wallet/WalletDetails";
import { decryptMnemonicKey } from "../lib/utils/crypto";
import {
  tryGetEncryptedKeyFromStorage,
  WALLET_STORAGE_KEY,
} from "../lib/utils/localStorage";
import { MnemonicWallet } from "../lib/wallet/MnemonicWallet";
import { WalletStore } from "../store/WalletStore";
import { toast } from "react-toastify";

const WalletDetailsPage: React.FC<any> = () => {
  const wallet = WalletStore.useState((s) => s.wallet);
  const [mnenomic, setMnemonic] = useState("");
  const [userPhrase, setUserPhrase] = useState("");
  const [showUnlock, setShowUnlock] = useState(false);
  const history = useHistory();

  const handleEscapeEvent = useCallback((e: any) => {
    if (e.keyCode === 27) {
      setShowUnlock(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleEscapeEvent, false);
    return () => {
      document.removeEventListener("keydown", handleEscapeEvent, false);
    };
  }, []);

  if (!wallet) {
    return <Redirect to={Routes.UNLOCK} />;
  }
  const viewMnemonic = () => {
    // decryptMnemonicKey(tryGetEncryptedKeyFromStorage() || "", )
    setShowUnlock(true);
  };

  const removeWallet = () => {
    localStorage.removeItem(WALLET_STORAGE_KEY);
    history.go(0);
  };

  const checkUserPhrase = () => {
    const encryptedKey = tryGetEncryptedKeyFromStorage();
    if (!encryptedKey) {
      toast.error("Cannot find wallet.");
    } else {
      try {
        const decryptKey = decryptMnemonicKey(encryptedKey, userPhrase);
        MnemonicWallet.fromMnemonic(decryptKey);
        setMnemonic(decryptKey);
        setShowUnlock(false);
      } catch (e) {
        toast.error("Incorrect password. Please try again.");
      }
    }
  };

  return (
    <div className="md:container md:mx-auto flex justify-center">
      <div className="mt-12 mb-12 p-3 ">
        <WalletDetails
          wallet={wallet as MnemonicWallet}
          mnemonicKey={mnenomic}
          viewMnemonic={viewMnemonic}
          removeWallet={removeWallet}
          secretKey={null}
          viewSecretKey={() => {}}
          printWallet={() => {}}
        />
      </div>
      <div
        className={`${
          !showUnlock ? "hidden" : ""
        } fixed z-10 top-0 left-0 right-0 bottom-0 w-full h-full bg-black bg-opacity-50 `}
      >
        <div className="flex w-full h-screen items-center justify-center px-2">
          <WhiteCard>
            <div className="w-full sm:w-128">
              <div className="flex items-start mt-2 text-white text-lg">
                <LockIcon />
                <p className="font-semibold ml-2">Enter your password</p>
              </div>
              <form
                className="mt-12"
                onSubmit={(e) => {
                  e.preventDefault();
                  checkUserPhrase();
                }}
              >
                <input
                  className=" bg-tokodot-dark-secondary  text-lg text-white rounded-lg px-6 py-3 mb-2 focus:outline-none focus:border-purple-400 "
                  type="password"
                  placeholder="Enter password"
                  onChange={(e) => setUserPhrase(e.target.value)}
                ></input>
                <div className="w-full flex flex-row mt-8 items-center justify-between">
                  <div className="">
                    <button
                      type="submit"
                      className="bg-tokodot-primary text-white border-2 border-tokodot-primary font-semibold rounded-lg text-lg py-2 px-6"
                    >
                      Unlock Wallet
                    </button>

                    <button
                      type="reset"
                      className="ml-4 border-2 border-white bg-white text-tokodot-dark-primary font-semibold rounded-lg text-lg py-2 px-6  focus:outline-none"
                      onClick={() => setShowUnlock(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </WhiteCard>
        </div>
      </div>
    </div>
  );
};
const LockIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
    <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
  </svg>
);
export default WalletDetailsPage;
