function FullPageDialog(props: any) {
  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 z-20 bg-tokodot-dark-primary flex justify-center py-12">
      <div className="w-2/3 flex flex-col items-start bg-tokodot-dark-primary rounded-xl  mx-4 overflow-y-scroll">
        <div className="w-full flex items-center justify-between text-white px-6 pt-6 pb-2">
          <p className="font-medium text-2xl">{props.title}</p>
          <button type="button" onClick={props.onClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </div>
        <div className="w-full my-2 border border-t-1 border-gray-500"></div>
        <div className="w-full p-6 ">{props.children}</div>
      </div>
    </div>
  );
}

export default FullPageDialog;
