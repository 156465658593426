import React from "react";
import { Jazzicon } from "@ukstv/jazzicon-react";
import { getActiveNetwork } from "../../lib/network";
import { AssetInfo } from "../../lib/hooks/useAssetInfo";
import { Link } from "react-router-dom";
import { Routes } from "../../App";
import { walletHasSufficientBalance } from "../../lib/utils/lib";

export interface WalletAssetsProps {
  assets: { [assetId: string]: AssetInfo };
  walletAddress: string;
  onClickShare?: (id: any) => void;
  onClickEdit?: (id: any) => void;
  isLoadingAssetInfo: boolean;
  actions: boolean;
}

const AssetList = ({ children, actions }: any) => (
  // <table className="w-full table-fixed text-white">
  //   <thead className="text-tokodot-gray h-12 text-sm">
  //     <th className="text-left ">Token Name</th>
  //     <th className="text-right pr-12">Amount</th>
  //     {actions && <th className="text-center">Action</th>}
  //   </thead>
  //   <tbody className="">{children}</tbody>
  // </table>
  <div
    className={`tokens-scroll w-full grid overflow-x-scroll overflow-y-hidden gap-x-2 ${
      actions ? "grid-cols-2 md:grid-cols-3 " : "grid-cols-2"
    }`}
  >
    <span className="w-32  font-bold text-base text-tokodot-gray mb-2">
      Token Name
    </span>
    <span className="font-bold text-base text-tokodot-gray text-right mb-2">
      Amount
    </span>
    {actions && (
      <span className="font-bold text-base text-tokodot-gray text-right pr-8 mb-2 w-52 hidden md:block">
        Actions
      </span>
    )}
    {children}
  </div>
);

const AssetListItem = ({
  name,
  unit_name,
  id,
  bal,
  last,
  manager,
  walletAddress,
  onClickShare,
  onClickEdit,
  actions,
}: any) => (
  <>
    <Link
      to={"/asset/" + id}
      className=" flex flex-row items-center h-16 w-48"
      href={"/asset/" + id}
    >
      {name === "USDC" ? (
        <USDC />
      ) : (
        <div className="bg-tokodot-dark-secondary text-white rounded-full p-2">
          <AssetIcon />
        </div>
      )}
      <span className="ml-3 font-medium text-md text-white break-all">
        {name}
      </span>
      {/* <a
        target="_blank"
        rel="noreferrer"
        href={getActiveNetwork().explorerUrl + "/asset/" + id}
        className="text-tokodot-primary text-xs font-bold"
      >
        ID: {id}
      </a> */}
    </Link>
    <div className=" flex flex-row items-center justify-end text-md h-16 ">
      <span className="font-semibold text-white text-md break-all">
        {bal} {unit_name}
      </span>
      {/* <span className="ml-2 font-medium text-gray-500">{unit_name}</span> */}
    </div>
    {actions && (
      <div className=" flex flex-row items-center justify-end w-full md:w-52 col-span-2 md:col-span-1">
        {manager === walletAddress && (
          <button
            className="mr-2 bg-tokodot-dark-secondary hover:bg-tokodot-primary text-white rounded-lg py-2 px-3"
            onClick={() => onClickEdit(id)}
          >
            <EditIcon />
          </button>
        )}
        <button
          className=" bg-tokodot-dark-secondary hover:bg-tokodot-primary text-white rounded-lg py-2 px-8 font-bold text-base"
          onClick={() => onClickShare(id)}
        >
          Share
        </button>
      </div>
    )}
  </>
);

const LoadingPulse = ({ actions }: any) => (
  <>
    <div className="h-6 bg-tokodot-dark-secondary rounded w-2/3 animate-pulse col-span-1 mb-2"></div>
    <div className="h-6 bg-tokodot-dark-secondary rounded animate-pulse col-span-1 ml-2 mb-2"></div>
    {actions && (
      <div className="text-right h-6 bg-tokodot-dark-secondary rounded w-full animate-pulse col-span-2 mb-4 md:col-span-1 md:ml-2 md:mb-2"></div>
    )}
  </>
);

const WalletAssets: React.FC<WalletAssetsProps> = ({
  assets,
  walletAddress,
  onClickShare,
  onClickEdit,
  isLoadingAssetInfo,
  actions,
}) => (
  <div className="tokens-scroll w-full max-h-128 px-8 py-6 bg-tokodot-dark-primary rounded-xl overflow-y-scroll shadow-xl">
    <AssetList actions={actions}>
      {isLoadingAssetInfo && (
        <>
          <LoadingPulse actions={actions} />
          <LoadingPulse actions={actions} />
        </>
      )}
      {!isLoadingAssetInfo &&
        Object.keys(assets)
          .sort((a, b) => {
            const assetA = assets[a];
            const assetB = assets[b];
            if (assetA.info.managerAddress === walletAddress) {
              if (assetA.info.name < assetB.info.name) {
                return -1;
              } else {
                return -50;
              }
            } else {
              if (assetA.info.name < assetB.info.name) {
                return 50;
              } else {
                return 1;
              }
            }
          })
          .map((assetId, i) => (
            <AssetListItem
              key={assetId}
              id={assetId}
              name={assets[assetId].info.name}
              unit_name={assets[assetId].info.unitName}
              bal={assets[assetId].displayBalance}
              last={i === Object.keys(assets).length - 1}
              manager={assets[assetId].info.managerAddress}
              walletAddress={walletAddress}
              onClickShare={onClickShare}
              onClickEdit={onClickEdit}
              actions={actions}
            />
          ))}
      {!isLoadingAssetInfo && Object.keys(assets).length === 0 && (
        <div className="font-bold text-tokodot-gray mt-6">No assets found</div>
      )}
    </AssetList>
  </div>
);

const AssetIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
    <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
    <line x1="12" y1="22.08" x2="12" y2="12"></line>
  </svg>
);

const USDC = () => (
  <svg
    width="32"
    data-name="86977684-12db-4850-8f30-233a7c267d11"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 2000 2000"
  >
    <path
      d="M1000 2000c554.17 0 1000-445.83 1000-1000S1554.17 0 1000 0 0 445.83 0 1000s445.83 1000 1000 1000z"
      fill="#2775ca"
    />
    <path
      d="M1275 1158.33c0-145.83-87.5-195.83-262.5-216.66-125-16.67-150-50-150-108.34s41.67-95.83 125-95.83c75 0 116.67 25 137.5 87.5 4.17 12.5 16.67 20.83 29.17 20.83h66.66c16.67 0 29.17-12.5 29.17-29.16v-4.17c-16.67-91.67-91.67-162.5-187.5-170.83v-100c0-16.67-12.5-29.17-33.33-33.34h-62.5c-16.67 0-29.17 12.5-33.34 33.34v95.83c-125 16.67-204.16 100-204.16 204.17 0 137.5 83.33 191.66 258.33 212.5 116.67 20.83 154.17 45.83 154.17 112.5s-58.34 112.5-137.5 112.5c-108.34 0-145.84-45.84-158.34-108.34-4.16-16.66-16.66-25-29.16-25h-70.84c-16.66 0-29.16 12.5-29.16 29.17v4.17c16.66 104.16 83.33 179.16 220.83 200v100c0 16.66 12.5 29.16 33.33 33.33h62.5c16.67 0 29.17-12.5 33.34-33.33v-100c125-20.84 208.33-108.34 208.33-220.84z"
      fill="#fff"
    />
    <path
      d="M787.5 1595.83c-325-116.66-491.67-479.16-370.83-800 62.5-175 200-308.33 370.83-370.83 16.67-8.33 25-20.83 25-41.67V325c0-16.67-8.33-29.17-25-33.33-4.17 0-12.5 0-16.67 4.16-395.83 125-612.5 545.84-487.5 941.67 75 233.33 254.17 412.5 487.5 487.5 16.67 8.33 33.34 0 37.5-16.67 4.17-4.16 4.17-8.33 4.17-16.66v-58.34c0-12.5-12.5-29.16-25-37.5zM1229.17 295.83c-16.67-8.33-33.34 0-37.5 16.67-4.17 4.17-4.17 8.33-4.17 16.67v58.33c0 16.67 12.5 33.33 25 41.67 325 116.66 491.67 479.16 370.83 800-62.5 175-200 308.33-370.83 370.83-16.67 8.33-25 20.83-25 41.67V1700c0 16.67 8.33 29.17 25 33.33 4.17 0 12.5 0 16.67-4.16 395.83-125 612.5-545.84 487.5-941.67-75-237.5-258.34-416.67-487.5-491.67z"
      fill="#fff"
    />
  </svg>
);

const EditIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z"></path>
  </svg>
);

export default WalletAssets;
