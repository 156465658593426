import algosdk from "algosdk";
import { getAlgod, getIndexer } from "../utils/lib";
import { WalletBalance } from "../wallet/types";
import { AssetInformation, AssetParams } from "./types";

// TODO: merge these two into one request
export async function balanceOf(address: string): Promise<WalletBalance> {
  // const algod = getAlgod();
  const indexer = getIndexer();
  try {
    const response = await indexer.lookupAccountByID(address).do();
    const account = response["account"];
    const bal: WalletBalance = {
      algos: account["amount"],
      algosWithoutPendingRewards: account["amount-without-pending-rewards"],
    };
    return bal;
  } catch {}
  return {
    algos: 0,
    algosWithoutPendingRewards: 0,
  };
}

export async function balanceOfAssets(
  address: string
): Promise<AssetInformation[]> {
  const indexer = getIndexer();
  // const algod = getAlgod();
  try {
    const response = await indexer.lookupAccountByID(address).do();
    const account = response["account"];
    if ("assets" in account && account.assets.length > 0) {
      const assets: AssetInformation[] = account.assets.map((x: any) => {
        const asset: AssetInformation = {
          amount: x["amount"],
          assetId: x["asset-id"],
          creator: x["creator"],
          isFrozen: x["is-frozen"],
        };
        return asset;
      });
      return assets;
    }
  } catch {}
  return [];
}

export async function getAssetParams(
  assetId: string
): Promise<AssetParams | null> {
  const indexer = getIndexer();
  try {
    const response = await indexer.lookupAssetByID(parseInt(assetId)).do();
    if ("asset" in response) {
      const params = response["asset"]["params"];
      const assetParams: AssetParams = {
        clawbackAddress: params["clawback"],
        defaultFrozen: params["default-frozen"],
        creatorAddress: params["creator"],
        total: params["total"],
        unitName: params["unit-name"],
        freezeAddress: params["freeze"],
        managerAddress: params["manager"],
        decimals: params["decimals"],
        name: params["name"],
        reserveAddress: params["reserve"],
        metadataHash: params["metadata-hash"],
        url: params["url"],
        id: parseInt(assetId),
      };
      return assetParams;
    }
  } catch {}
  return null;
}

export async function searchAssets(query: string): Promise<AssetParams[]> {
  const indexer = getIndexer();
  const assets = [];
  try {
    const response = await indexer.searchForAssets().name(query).do();
    if ("assets" in response) {
      for (const asset of response["assets"]) {
        const assetId = asset["index"];
        const params = asset["params"];
        const assetParams: AssetParams = {
          clawbackAddress: params["clawback"],
          defaultFrozen: params["default-frozen"],
          creatorAddress: params["creator"],
          total: params["total"],
          unitName: params["unit-name"],
          freezeAddress: params["freeze"],
          managerAddress: params["manager"],
          decimals: params["decimals"],
          name: params["name"],
          reserveAddress: params["reserve"],
          metadataHash: params["metadata-hash"],
          url: params["url"],
          id: assetId,
        };
        assets.push(assetParams);
      }
    }
  } catch (e) {}
  return assets;
}
