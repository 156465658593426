import { useState } from "react";
import { useHistory } from "react-router-dom";
import { WalletStore } from "../store/WalletStore";
import Coinbase from "./Coinbase";
import PopupDailog from "./PopupDailog";

const BuyAsset = () => {
  const [onNextPage, setOnNextPage] = useState<boolean | undefined>(false);
  const [onWalletPage, setOnWalletPage] = useState<boolean | undefined>(false);
  const history = useHistory();
  const wallet = WalletStore.useState((s) => s.wallet);
  var walletAddress = "";
  if (wallet) {
    walletAddress = wallet?.getAddress();
  }

  return (
    <PopupDailog title={"Transfer"} onClose={() => history.goBack()}>
      {!onNextPage && !onWalletPage && (
        <div className="flex flex-col items-center rounded-xl mx-4">
          <p className="font-bold text-xl self-start my-4 text-white">
            Steps to transfer Algos to Tokodot
          </p>
          {!onNextPage && !onWalletPage && (
            <div>
              <button
                className="text-center text-md font-semibold text-white bg-tokodot-primary mt-4 px-6 py-4 mx-2 rounded-lg"
                onClick={() => {
                  setOnNextPage(true);
                  setOnWalletPage(true);
                }}
              >
                I have an exisiting wallet with Algos I can transfer
              </button>
              <button
                className="text-center text-md font-semibold text-white bg-tokodot-primary mt-4 mb-10 px-6 pr-10 py-4 mx-2 rounded-lg"
                onClick={() => {
                  setOnNextPage(true);
                }}
              >
                I want to buy Algos from Coinbase and transfer
              </button>
            </div>
          )}
        </div>
      )}
      {onNextPage && !onWalletPage && <Coinbase></Coinbase>}
      {onNextPage && onWalletPage && (
        <div className="max-w-auto flex flex-col items-center  rounded-xl p-8 mx-4 text-white">
          <span className="self-start text-lg font-bold mb-4">
            You can transfer Algos from your wallet to this address:
          </span>
          <span className="self-start font-black text-xl mb-4 break-all">
            {walletAddress}
          </span>
          <button
            onClick={() => {
              navigator.clipboard.writeText(walletAddress);
            }}
            className="text-tokodot-dark-primary bg-white font-bold rounded-lg text-lg py-2 px-8 focus:outline-none"
          >
            Copy
          </button>
        </div>
      )}
    </PopupDailog>
  );
};

export default BuyAsset;
