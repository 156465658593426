import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { WalletStore } from "../store/WalletStore";
import { WhiteCard } from "./common";
import { MnemonicWallet } from "../lib/wallet/MnemonicWallet";
import { encryptMnemonicKey } from "../lib/utils/crypto";
import { saveEncryptedKey } from "../lib/utils/localStorage";
import { Routes } from "../App";

const LockWallet = () => {
  const walletKey = WalletStore.useState((s) => s.mnemonicKey);
  const [userPhrase, setUserPhrase] = useState("");
  const history = useHistory();
  const [isPhraseValid, setIsPhraseValid] = useState<boolean | undefined>(
    false
  );

  useEffect(() => {
    if (walletKey === "") {
      history.replace(Routes.CREATE);
    }
  }, [history, walletKey]);

  const switchToViewWalletPage = () => {
    const wallet = MnemonicWallet.fromMnemonic(walletKey);
    WalletStore.update((s) => {
      s.wallet = wallet;
      s.mnemonicKey = "";
    });

    // encrypt key before saving it to local storage
    const encryptedAES = encryptMnemonicKey(walletKey, userPhrase);
    saveEncryptedKey(encryptedAES);

    history.push(Routes.WALLET);
  };

  useEffect(() => {
    const passwordStrengthRegEx = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})" //1 capital, 1 num, 1 special char, at least 8char
    );
    if (process.env.NODE_ENV === "production") {
      if (passwordStrengthRegEx.test(userPhrase)) {
        setIsPhraseValid(true);
      } else {
        setIsPhraseValid(false);
      }
    } else {
      if (userPhrase.length > 4) {
        setIsPhraseValid(true);
      } else {
        setIsPhraseValid(false);
      }
    }
  }, [userPhrase]);

  return (
    <WhiteCard>
      <div className="w-full sm:w-128">
        <div className="flex items-start mt-2 text-white">
          <LockIcon />
          <p className="font-semibold ml-2 text-lg">Lock this wallet</p>
        </div>
        <form
          className="mt-8"
          onSubmit={(e) => {
            e.preventDefault();
            if (isPhraseValid) {
              switchToViewWalletPage();
            }
          }}
        >
          <input
            className="bg-tokodot-dark-secondary text-lg text-white rounded-lg px-6 py-3 mb-2 focus:outline-none focus:border-purple-400 "
            type="password"
            placeholder="Enter password"
            onChange={(e) => setUserPhrase(e.target.value)}
          ></input>
          <div>
            <p className="text-red-500 text-xs mt-2">
              Min 8 chars. 1 letter. 1 num. 1 special char.
            </p>
          </div>
          <div className="w-full flex flex-row mt-8 items-center justify-between">
            <button
              type="submit"
              disabled={!isPhraseValid}
              className="disabled:opacity-40 bg-tokodot-primary text-white border-2 border-tokodot-primary font-semibold rounded-lg text-lg py-2 px-8"
            >
              Lock Wallet
            </button>
          </div>
        </form>
      </div>
    </WhiteCard>
  );
};
const LockIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
    <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
  </svg>
);

const BookOpenIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"></path>
    <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"></path>
  </svg>
);

export default LockWallet;
