import React from "react";
import { Redirect } from "react-router-dom";
import { Routes } from "../App";
import { Circle } from "../components/common";
import WalletOptions from "../components/onboarding/WalletOptions";
import { tryGetEncryptedKeyFromStorage } from "../lib/utils/localStorage";

const WalletSelect = () => {
  if (tryGetEncryptedKeyFromStorage()) {
    return <Redirect to={Routes.UNLOCK} />;
  }

  return (
    <div className="container mx-auto w-full ">
      <div className="flex mt-12 text-2xl p-12">
        <Circle>1</Circle>
        <div className="flex flex-col text-tokodot-primary">
          <p className="ml-4 font-bold mt-2 text-white">
            Create or import a wallet to continue
          </p>
          <p className="ml-4 mt-3 font-medium text-xl text-tokodot-gray">
            Before you can interact with Tokodot, you will need a wallet
          </p>
        </div>
      </div>
      <div className="flex items-center justify-center px-4">
        <WalletOptions />
      </div>
      <div className="mt-24"></div>
    </div>
  );
};

export default WalletSelect;
