import { Routes } from "../App";
import Studio from "../components/studio/Studio";

const StudioPage = () => {
  return (
    <div className="md:container md:mx-auto w-full ">
      <div className="flex mt-12 md:mt-24 pb-12">
        <Studio />
      </div>
    </div>
  );
};

export default StudioPage;
