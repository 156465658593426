import { AlgoExchangePriceURI, DefaultConfig } from "./constants";
import { AlgoExchangePrice, NetworkConfig } from "./types";

export let activeNetwork: NetworkConfig = DefaultConfig;

export function getActiveNetwork() {
  return activeNetwork;
}

export function setNetwork(conf: NetworkConfig) {
  activeNetwork = conf;
}

export async function getExchangePrice(): Promise<AlgoExchangePrice | null> {
  try {
    const response = await fetch(AlgoExchangePriceURI);
    const body = await response.json();
    if ("data" in body) {
      const price: AlgoExchangePrice = {
        base: body["data"].base,
        currency: body["data"].currency,
        amount: body["data"].amount,
      };
      return price;
    }
  } catch {}
  return null;
}
