import { Redirect } from "react-router-dom";
import { Routes } from "../App";
import UnlockWallet from "../components/UnlockWallet";
import { tryGetEncryptedKeyFromStorage } from "../lib/utils/localStorage";

const UnlockWalletPage = (props: any) => {
  if (!tryGetEncryptedKeyFromStorage()) {
    return <Redirect to={Routes.NEW} />;
  }

  return (
    <div className="container mx-auto w-full ">
      <div className="flex mt-12 text-2xl p-12">
        <div className="flex flex-col">
          <p className="ml-4  font-bold text-white">
            We found an existing wallet in your browser, please unlock it
          </p>
        </div>
      </div>
      <div className="mt-12 md:mt-16 flex items-center justify-center px-2">
        <UnlockWallet />
      </div>
      <div className="mt-24"></div>
    </div>
  );
};

export default UnlockWalletPage;
