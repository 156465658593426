import React from "react";
import { Link } from "react-router-dom";
import { Routes } from "../../App";
import PlusGrad from "../../assets/plus_gradient.svg";
import RefreshGrad from "../../assets/refresh_gradient.svg";

const Card = ({ children }: any) => (
  <div className="w-full md:w-128 flex flex-col bg-tokodot-dark-primary border-2 md:border-0 md:shadow-lg rounded-xl py-12 px-12">
    {children}
  </div>
);

const WalletOptions = () => {
  return (
    <div className="w-full flex flex-col lg:flex-row items-center justify-around">
      <Card>
        <div className="mt-12 flex justify-center mb-7">
          <img src={PlusGrad} width={132} />
        </div>
        <div className="mt-6 flex">
          <p className=" font-bold text-xl text-white">Create a new wallet</p>
        </div>
        <div className="mt-6 flex">
          <p className="text-tokodot-gray font-medium text-lg">
            New users can start here and follow steps to create a new Algorand
            wallet
          </p>
        </div>
        <div className="mt-6 flex flex-row justify-between items-center">
          <Link to={Routes.CREATE}>
            <button className="bg-tokodot-primary text-white border-2 border-tokodot-primary font-semibold rounded-lg text-lg py-2 px-4">
              Create Wallet
            </button>
          </Link>
          {/* <p className="text-tokodot-purple underline font-semibold text-lg">
            Learn more
          </p> */}
        </div>
      </Card>
      <div className="mt-12 lg:mt-0"></div>
      <Card>
        <div className="mt-12 flex justify-center">
          <img src={RefreshGrad} width={128} />
        </div>
        <div className="mt-6 flex ">
          <p className=" font-bold text-xl text-white">
            Import an existing wallet
          </p>
        </div>
        <div className="mt-6 flex">
          <p className="text-tokodot-gray font-medium text-lg">
            If you already have an Algorand wallet, you can use it with Tokodot
          </p>
        </div>
        <div className="mt-6 flex flex-row justify-between items-center">
          <Link to={Routes.IMPORT}>
            <button className="border-2 border-white text-tokodot-dark-secondary bg-white font-semibold rounded-lg text-lg py-2 px-4">
              Import Wallet
            </button>
          </Link>
          {/* <p className="text-tokodot-purple underline font-semibold text-lg">
            Learn more
          </p> */}
        </div>
      </Card>
    </div>
  );
};

export default WalletOptions;
