import { useEffect, useState } from "react";
import { useParams } from "react-router";
import ExplorerAccount from "../containers/ExplorerAccount";
import { ObservingWallet } from "../lib/wallet/ObservingWallet";
import { WalletProvider } from "../lib/wallet/Wallet";

function ExplorerAccountPage() {
  const params: any = useParams();
  const [wallet, setWallet] = useState<null | WalletProvider>(null);

  useEffect(() => {
    if (params.address) {
      setWallet(ObservingWallet.fromAddress(params.address));
    }
  }, [params]);

  if (!wallet) {
    return null;
  }

  return (
    <div className="md:container md:mx-auto flex justify-center">
      <div className="mt-12 mb-12 p-3">
        <ExplorerAccount wallet={wallet}></ExplorerAccount>;
      </div>
    </div>
  );
}

export default ExplorerAccountPage;
