import { TransactionType } from "algosdk";
import { getIndexer } from "../utils/lib";
import { ObservingWallet } from "../wallet/ObservingWallet";
import { getTransaction } from "./Transaction";
import { Transaction } from "./types";

export async function getTransactions(account: string): Promise<Transaction[]> {
  const indexer = getIndexer();
  try {
    const response = await indexer.lookupAccountTransactions(account).do();
    if ("transactions" in response) {
      const transactions: any[] = response["transactions"];
      return transactions.map(getTransaction);
    }
  } catch {}
  return [];
}

export async function getAssetTransactions(
  assetId: string
): Promise<Transaction[]> {
  const indexer = getIndexer();
  try {
    const response = await indexer
      .lookupAssetTransactions(Number(assetId))
      .do();
    if ("transactions" in response) {
      const transactions: any[] = response["transactions"];
      return transactions.map(getTransaction);
    }
  } catch {}
  return [];
}

export async function getAssetAccounts(
  assetId: string
): Promise<ObservingWallet[]> {
  const indexer = getIndexer();
  try {
    const response = await indexer.lookupAssetBalances(Number(assetId)).do();
    if ("balances" in response) {
      const balances: any[] = response["balances"];
      return balances.map((balance) => {
        const observingWallet = ObservingWallet.fromAddress(balance["address"]);
        observingWallet.assetBalance = {
          [assetId]: {
            balance: balance["amount"],
            params: {
              amount: balance["amount"],
              assetId: Number(assetId),
              creator: "",
              isFrozen: false,
            },
          },
        };
        return observingWallet;
      });
    }
  } catch {}
  return [];
}
