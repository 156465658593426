import algosdk, { microalgosToAlgos } from "algosdk";
import { useCallback, useEffect, useState } from "react";
import { WalletProvider } from "../lib/wallet/Wallet";

interface AssetInputProps {
  decimals: number;
  initialValue: number;
  algoPrice: number;
  wallet: WalletProvider;
  onChange: any;
  className: string;
  disabled: boolean;
  algoOnly: boolean;
}

function microAlgosToDollar(algoPrice: number, microAlgos: number): number {
  const algoVal = microalgosToAlgos(microAlgos);
  const usdVal = algoVal * (algoPrice * 100);
  return Math.floor(usdVal);
}

function dollarToMicroAlgos(algoPrice: number, dollars: number) {
  const algoVal = dollars / (algoPrice * 100);
  return algosdk.algosToMicroalgos(algoVal);
}

function AssetInput(props: AssetInputProps) {
  const decimals = props.decimals || 0;
  const initialValue = props.initialValue || 0;
  const [value, setValue] = useState<number>(initialValue);
  const [strValue, setStrValue] = useState<string>(Number(0).toFixed(decimals));
  const [currType, setCurrType] = useState<"ALGO" | "USD">("ALGO");
  const [altValue, setAltValue] = useState<number>(initialValue);

  const alternateType = currType === "ALGO" ? "USD" : "ALGO";
  const valueBasedOnAltType =
    alternateType === "ALGO"
      ? microalgosToAlgos(altValue)
      : (altValue / 100).toFixed(2);

  const algoPrice = props.algoPrice;

  const changeInputCurrencyType = () => {
    if (currType === "ALGO") {
      const usdVal = microAlgosToDollar(algoPrice, value);
      setCurrType("USD");
      setValue(usdVal);
    } else {
      const microAlgos = dollarToMicroAlgos(algoPrice, value);
      setCurrType("ALGO");
      setValue(microAlgos);
    }
  };

  const getDecimalsAsString = useCallback((value: number, decimals: number) => {
    let newStr = "";
    if (decimals !== 0) {
      const first = Math.floor(value / 10 ** decimals);
      const afterDecimal = value % 10 ** decimals;
      newStr = `${first}.${"0".repeat(
        decimals - afterDecimal.toString().length > 0
          ? decimals - afterDecimal.toString().length
          : 0
      )}${afterDecimal}`;
    } else {
      newStr = value.toString();
    }
    return newStr;
  }, []);

  useEffect(() => {
    let newStr;
    if (currType === "USD") {
      newStr = getDecimalsAsString(value, 2);
      const microAlgos = dollarToMicroAlgos(algoPrice, value);
      setAltValue(microAlgos);
    } else {
      newStr = getDecimalsAsString(value, decimals);
      const usdVal = microAlgosToDollar(algoPrice, value);
      setAltValue(usdVal);
    }
    setStrValue(newStr);
  }, [value, setStrValue, decimals, getDecimalsAsString, currType]);

  useEffect(() => {
    props.onChange && props.onChange(value);
    if (props.onChange) {
      props.onChange(currType === "ALGO" ? value : altValue);
    }
  }, [props, value]);

  useEffect(() => {
    setCurrType("ALGO");
    setValue(initialValue);
  }, [props.algoOnly]);

  return (
    <div className="flex flex-col">
      <div className="relative">
        <input
          value={strValue}
          onChange={(e: any) => {
            let isnum = !isNaN(Number(e.target.value));
            if (!isnum) {
              return;
            }
            const newStrValue = String(e.target.value);
            if (e.target.value === strValue) return;
            const digit = Number(newStrValue.charAt(newStrValue.length - 1));
            const newValue = value * 10 + digit;
            setValue(newValue);
          }}
          onKeyDown={(e: any) => {
            if (e.keyCode === 8) {
              // ondelete
              const newValue = Math.floor(value / 10);
              setValue(newValue);
            }
          }}
          type="text"
          className={props.className + " w-full z-0"}
          disabled={props.disabled}
        />
        {props.algoOnly && (
          <div className="absolute top-0 right-3 mt-2 z-10">
            <span className="mr-4 text-gray-500 font-medium">{currType}</span>
            <button
              type="button"
              className="text-white cursor-pointer bg-tokodot-dark-primary px-2 py-1 rounded-xl text-md font-medium"
            >
              MAX
            </button>
          </div>
        )}
      </div>
      {props.algoOnly && (
        <div className="self-end mt-2">
          <button
            type="button"
            className="text-white flex items-center"
            onClick={changeInputCurrencyType}
          >
            <SwitchIcon />
            <span className="ml-1 text-sm">
              {valueBasedOnAltType} {alternateType}
            </span>
          </button>
        </div>
      )}
    </div>
  );
}

const SwitchIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <polyline points="17 1 21 5 17 9"></polyline>
    <path d="M3 11V9a4 4 0 0 1 4-4h14"></path>
    <polyline points="7 23 3 19 7 15"></polyline>
    <path d="M21 13v2a4 4 0 0 1-4 4H3"></path>
  </svg>
);

export default AssetInput;
