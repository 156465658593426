import React, { useCallback, useEffect, useState, useMemo } from "react";
import {
  AssetBalance,
  WalletAssetBalance,
  WalletBalance,
} from "../lib/wallet/types";
import { WalletProvider } from "../lib/wallet/Wallet";
import { getActiveNetwork, getExchangePrice } from "../lib/network";
import { AlgoExchangePrice } from "../lib/network/types";
import algosdk, { microalgosToAlgos } from "algosdk";
import { Transaction } from "../lib/explorer/types";
import {
  getAssetAccounts,
  getAssetTransactions,
  getTransactions,
} from "../lib/explorer/Explorer";
import WalletCard from "../components/wallet/WalletCard";
import WalletActivity from "../components/wallet/WalletActivity";
import { formatBalance, requestFreeAlgos } from "../lib/utils/lib";
import AddressQRCode from "../components/AddressQRCode";
import SendAsset from "../components/SendAsset";
import AddAsset from "../components/AddAsset";
import useWalletEventListener from "../lib/hooks/useEventListener";
import useToggle from "../lib/hooks/useToggle";
import useAssetInfo from "../lib/hooks/useAssetInfo";
import { WalletStore } from "../store/WalletStore";
import { Routes } from "../App";
import GiftSVG from "../assets/gift.svg";
import {
  Link,
  Redirect,
  Route,
  useHistory,
  useRouteMatch,
  withRouter,
} from "react-router-dom";
import BuyAsset from "../components/BuyAsset";

import ShareAsset from "../components/ShareAsset";
import ClaimDrop from "../components/ClaimDrop";
import { MnemonicWallet } from "../lib/wallet/MnemonicWallet";
import { getAllRevDrops } from "../lib/drop/createDrop";
import WalletAssets from "../components/wallet/WalletAssets";
import { getAssetParams } from "../lib/asset/Asset";
import { AssetParams } from "../lib/asset/types";

export interface ExplorerAssetProps {
  wallet: WalletProvider | undefined;
  assetId: string;
}

const DISPLAY_PROPERTIES = [
  ["id", "token id"],
  ["name", "name"],
  ["unitName", "unit name"],
  ["total", "unit name"],
  ["decimals", "decimals"],
  ["creatorAddress", "creator"],
  ["managerAddress", "manager"],
  ["reserveAddress", "reserve"],
  ["freezeAddress", "freeze"],
  ["clawbackAddress", "clawback"],
  ["metadataHash", "meta hash"],
  ["url", "url"],
];

const ExplorerAsset: React.FC<ExplorerAssetProps> = ({ wallet, assetId }) => {
  const match = useRouteMatch();
  const history = useHistory();

  const [algoPrice, setAlgoPrice] = useState<AlgoExchangePrice | null>(null);

  useEffect(() => {
    async function getExchangeRate() {
      const price = await getExchangePrice();
      setAlgoPrice(price);
    }
    const interval = setInterval(getExchangeRate, 5000);
    return () => clearInterval(interval);
  }, []);

  // get transactions
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  useEffect(() => {
    async function getAllTransactions() {
      const txns = await getAssetTransactions(assetId);
      setTransactions(txns);
    }
    getAllTransactions();
  }, [setTransactions, wallet]);

  const [assetInfo, setAssetInfo] = useState<AssetParams | null>(null);
  useEffect(() => {
    async function _getAssetInfo() {
      const _info = await getAssetParams(assetId);
      setAssetInfo(_info);
    }
    _getAssetInfo();
  }, []);

  const [assetAccounts, setAssetAccounts] = useState<WalletProvider[]>([]);
  useEffect(() => {
    async function _getAssetAccounts() {
      const accounts = await getAssetAccounts(assetId);
      setAssetAccounts(accounts);
    }
    _getAssetAccounts();
  }, []);

  const transaction_fields = transactions.map((txn) => {
    const receiver = txn["asset-transfer-transaction"]
      ? txn["asset-transfer-transaction"].receiver
      : "";
    const amount = txn["asset-transfer-transaction"]
      ? txn["asset-transfer-transaction"].amount
      : 0;
    const assetBalance = formatBalance(
      Number(assetInfo?.decimals || 0),
      Number(amount)
    );
    const asset = {
      name: assetInfo?.name || "",
      unit_name: assetInfo?.unitName || "",
      amount: assetBalance || 0,
    };
    return {
      txid: txn.id,
      asset,
      from: txn.sender,
      to: receiver,
    };
  });

  return (
    <div className="grid grid-cols-1">
      <div className="">
        <div className="w-full px-8 py-10 bg-tokodot-dark-primary rounded-xl shadow-xl">
          {assetInfo && (
            <div className="grid grid-cols-2 gap-x-2 gap-y-2">
              {DISPLAY_PROPERTIES.map((property, index) => {
                const propKey = property[0] as keyof typeof assetInfo;
                const value = assetInfo[propKey];
                if (value === undefined) return null;
                return (
                  <div className="flex flex-col" key={index}>
                    <span className="text-xs text-tokodot-gray font-bold uppercase">
                      {property[1]}
                    </span>
                    <span className="text-sm text-white font-medium break-all">
                      {value}
                    </span>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      {wallet && (
        <div className="mt-12">
          <div
            className="flex items-end  rounded-xl text-white pt-4 pb-6 px-8 shadow-xl " // hover:text-black"
            style={{
              backgroundImage: "linear-gradient(165deg, #3C95DA, #704DDF)",
            }}
          >
            {/* <Gift /> */}
            <img src={GiftSVG} className="w-12 mb-1" />
            <div className="w-full flex items-center justify-between ml-6">
              <div className="">
                <span className="text-2xl font-bold">
                  Share {assetInfo?.name}
                </span>
                <span className="text-base ml-4 font-medium">
                  You can easily create drop wallets with {assetInfo?.unitName}{" "}
                  tokens
                </span>
              </div>
              <button
                className="bg-white text-black font-bold rounded-lg shadow-lg px-6 py-2 ml-6 mr-12 transform hover:scale-110 motion-reduce:tranform-none cursor-pointer"
                onClick={() => {
                  history.push(match.url + "/share");
                }}
              >
                Share
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="mt-12">
        <p className="font-black text-2xl mb-2 text-tokodot-primary">
          Token Holders
        </p>
        <div className="tokens-scroll w-full max-h-128 px-8 py-6 bg-tokodot-dark-primary rounded-xl overflow-y-scroll shadow-xl">
          <div
            className={`tokens-scroll w-full grid overflow-x-scroll overflow-y-hidden gap-x-2 grid-cols-5`}
          >
            <span className="font-bold text-base text-tokodot-gray mb-2 col-span-3">
              Address
            </span>
            <span className="font-bold text-base text-tokodot-gray text-right mb-2 col-span-2">
              Amount
            </span>
            {assetAccounts.map((account, index) => (
              <React.Fragment key={index}>
                <span className="font-medium text-md text-white break-all col-span-3 mb-2">
                  <Link to={"/account/" + account.getAddress()}>
                    {account.getAddress()}
                  </Link>
                </span>
                <span className="font-medium text-md text-white text-right break-all col-span-2 mb-2">
                  {formatBalance(
                    Number(assetInfo?.decimals) || 0,
                    Number(account.getAssetBalance(assetId).balance)
                  )}{" "}
                  {assetInfo?.unitName}
                </span>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
      <div className="mt-12">
        <p className="font-black text-2xl mb-2 text-tokodot-primary">
          Recent Activity
        </p>
        <div className="tokens-scroll w-full overflow-x-scroll flex flex-col px-8 py-6 bg-tokodot-dark-primary rounded-xl shadow-xl">
          <table className="table-auto">
            <tbody>
              {transaction_fields.map((txn, index) => {
                return (
                  <tr
                    key={index}
                    className="table-row mb-6 items-center text-md font-medium text-white h-16"
                  >
                    <td>
                      <Link to={"/account/" + txn.from}>
                        {txn.from.substring(0, 8)}
                      </Link>
                    </td>
                    <td>
                      <span className="ml-4">sent</span>
                    </td>
                    <td>
                      <span className="ml-4">{txn.asset.amount}</span>
                    </td>
                    <td>
                      <span className="ml-4">{txn.asset.unit_name}</span>
                    </td>
                    <td>
                      <span className="ml-4">to</span>
                    </td>
                    <td>
                      <span className="ml-4">
                        <Link to={"/account/" + txn.to}>
                          {txn.to.substring(0, 8) || "N/A"}
                        </Link>
                      </span>
                    </td>
                    <td>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={
                          getActiveNetwork().explorerUrl +
                          "/transaction/" +
                          txn.txid
                        }
                        className="hover:text-purple-500"
                      >
                        <ExternalLink />
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {wallet && assetInfo && (
        <Route path={`${match.path}/share`}>
          <ShareAsset
            wallet={wallet}
            assets={{
              [assetId]: {
                info: assetInfo,
                balance: wallet.getAssetBalance(assetId).balance,
                displayBalance: formatBalance(
                  Number(assetInfo?.decimals || 0),
                  Number(wallet.getAssetBalance(assetId).balance)
                ),
              },
            }}
            closeShareForm={() => history.goBack()}
            selectedAssetId={assetId}
            algoPrice={algoPrice?.amount ? Number(algoPrice?.amount) : 0}
          ></ShareAsset>
        </Route>
      )}
    </div>
  );
};

const Spinner = () => (
  <svg
    className="animate-spin-fast -ml-1 mr-3 h-5 w-5 text-white"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
);

const Plus = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <line x1="12" y1="5" x2="12" y2="19"></line>
    <line x1="5" y1="12" x2="19" y2="12"></line>
  </svg>
);

const ExternalLink = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
    <polyline points="15 3 21 3 21 9"></polyline>
    <line x1="10" y1="14" x2="21" y2="3"></line>
  </svg>
);

export default ExplorerAsset;
