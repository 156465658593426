const BasicToken = ({ register, errors }: any) => {
  return (
    <div className="w-full flex flex-col">
      <label
        htmlFor=""
        className="mt-6 text-tokodot-primary font-semibold text-sm"
      >
        Token Name
      </label>
      <input
        {...register("assetName", {
          required: true,
        })}
        defaultValue={""}
        className="bg-tokodot-dark-secondary text-white text-base  px-4 py-3 mt-1 rounded-md "
        name="assetName"
        placeholder="Sikka"
        maxLength={32}
      />
      {errors.assetName && (
        <p className="mt-1 ml-1 text-sm text-red-500">
          You need to enter an asset name.
        </p>
      )}
      <label
        htmlFor=""
        className="mt-6 text-tokodot-primary font-semibold text-sm"
      >
        Unit Name
      </label>
      <input
        {...register("unitName", {
          required: true,
        })}
        defaultValue={""}
        className="bg-tokodot-dark-secondary text-white text-base px-4 py-3 mt-1 rounded-md uppercase"
        name="unitName"
        placeholder="SKA"
        maxLength={8}
      />
      {errors.unitName && (
        <p className="mt-1 ml-1 text-sm text-red-500">
          You need to enter a unit name.
        </p>
      )}
      <label
        htmlFor=""
        className="mt-6 text-tokodot-primary font-semibold text-sm"
      >
        Total Tokens
      </label>
      <input
        {...register("totalAssets", {
          required: true,
        })}
        className="bg-tokodot-dark-secondary text-white text-base px-4 py-3 mt-1 rounded-md"
        name="totalAssets"
        type="number"
        placeholder="100"
        min={1}
      />
      {errors.totalAssets && (
        <p className="mt-1 ml-1 text-sm text-red-500">
          Total has to be greater than 0.
        </p>
      )}
    </div>
  );
};

export default BasicToken;
