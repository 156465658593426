import VerifyWallet from "../components/VerifyWallet";
import { Circle } from "../components/common";

const VerifyWalletPage = (props: any) => {
  return (
    <div className="container mx-auto w-full ">
      <div className="flex mt-12  text-2xl p-12">
        <div className="-ml-3 mt-1">
          <Circle>3</Circle>
        </div>
        <div className="flex flex-col ">
          <p className="ml-4 font-bold mt-2 text-white">
            Let’s verify your newly created wallet
          </p>
        </div>
      </div>
      <div className="mt-12 md:mt-12 flex flex-col items-center justify-center px-2">
        <VerifyWallet />
      </div>
      <div className="mt-12"></div>
    </div>
  );
};

const LockIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
    <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
  </svg>
);

export default VerifyWalletPage;
