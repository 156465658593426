import { useState } from "react";
import { WalletProvider } from "../lib/wallet/Wallet";
import SelectSearch, { SelectSearchOption } from "react-select-search";

import { searchAssets } from "../lib/asset/Asset";
import "./AddAssetSelect.css";
import { AssetInfo } from "../lib/hooks/useAssetInfo";
import { toast } from "react-toastify";
import PopupDailog from "./PopupDailog";
import { microalgosToAlgos } from "algosdk";

interface AnalyticsWindow extends Window {
  analytics?: any;
}

const Option = (props: any, option: any, snapshot: any, className: any) => {
  return (
    <button {...props} className={className} type="button">
      <span>{option.name}</span>
      <span>ID: {option.value}</span>
    </button>
  );
};

interface AddAssetProps {
  assets: { [assetId: string]: AssetInfo };
  wallet?: WalletProvider;
  onClickCancel?: () => void;
}

const AddAsset = ({ assets, wallet, onClickCancel }: AddAssetProps) => {
  const [selectedToken, setSelectedToken] = useState<any>(null);
  const [waitingForTxn, setWaitingForTxn] = useState(false);

  const onSubmit = async (data: any) => {
    if (!wallet) {
      toast.info("No wallet found.");
      return;
    }
    const availableBal = microalgosToAlgos(
      Number(
        wallet.getAvailableBalance() > 0 ? wallet.getAvailableBalance() : 1
      )
    );
    if (availableBal < 0.1 + 0.001) {
      toast.info(
        "Do not have the minimum balance to optin to more assets. Need atleast 0.1 ALGO more"
      );
      return;
    }
    if (!selectedToken) return;
    setWaitingForTxn(true);
    const success = await wallet?.sendAsset(
      wallet.getAddress(),
      0,
      selectedToken
    );
    const customWindow: AnalyticsWindow = window;
    if (success) {
      toast.success("Successfully opted-in");
      onClickCancel && onClickCancel();
      if (customWindow.analytics) {
        customWindow.analytics.track("optin_asset.success", {
          assetId: selectedToken,
        });
      }
    } else {
      if (customWindow.analytics) {
        customWindow.analytics.track("optin_asset.failed", {
          assetId: selectedToken,
        });
      }
      toast.error("Failed to opt-in to asset.");
    }
    setWaitingForTxn(false);
  };

  const myAssets = Object.keys(assets);

  const loadOptions = async (query: string) => {
    if (query === "" || query.length < 3) {
      return [];
    }
    const assets = await searchAssets(query);
    const mapped = assets.map(
      (a) => ({ value: a.id, name: a.name } as SelectSearchOption)
    );
    const filtered = mapped.filter((a) => !myAssets.includes(String(a.value)));
    return filtered;
  };

  const handleOnChange = (asset_id: any) => {
    setSelectedToken(asset_id);
  };

  return (
    <PopupDailog title={"Opt-In Token"} onClose={onClickCancel}>
      <form className="flex flex-col mt-2">
        <label
          htmlFor=""
          className="ml-1 text-tokodot-primary font-bold text-base mb-2"
        >
          Token
        </label>
        <SelectSearch
          options={[]}
          search
          getOptions={loadOptions}
          emptyMessage="Not found"
          placeholder="Select a Token"
          renderOption={Option}
          onChange={handleOnChange}
          disabled={waitingForTxn}
        />
        {selectedToken && (
          <p className="ml-2 mt-2 text-tokodot-primary text-sm font-bold">
            ID: {selectedToken}
          </p>
        )}
        <button
          className="disabled:opacity-40 mt-6  px-6 py-4  mx-2 text-center text-sm font-semibold text-white uppercase bg-tokodot-primary focus:outline-none flex justify-center items-center rounded-lg"
          disabled={waitingForTxn || !selectedToken}
          onClick={onSubmit}
          type="button"
        >
          {waitingForTxn && <Spinner />}
          Opt-In
        </button>
      </form>
    </PopupDailog>
  );
};

const Spinner = () => (
  <svg
    className="animate-spin-fast -ml-1 mr-3 h-5 w-5 text-white"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
);

export default AddAsset;
