import algosdk from "algosdk";
import { seedFromMnemonic } from "algosdk/dist/types/src/mnemonic/mnemonic";
import { EncryptFN } from "../utils/crypto";
import { WalletNameType } from "./types";
import { WalletProvider } from "./Wallet";

export class MnemonicWallet extends WalletProvider {
  type: WalletNameType;
  address: string;
  private mnemonic: string;
  private secretKey: Uint8Array;

  constructor(mnemonic: string) {
    super();
    this.type = "mnemonic";
    this.mnemonic = mnemonic;
    try {
      const account = algosdk.mnemonicToSecretKey(mnemonic);
      this.address = account.addr;
      this.secretKey = account.sk;
    } catch {
      throw new Error("Invalid mnemonic");
    }
  }

  static create(): MnemonicWallet {
    const account = algosdk.generateAccount();
    const mnemonic = algosdk.secretKeyToMnemonic(account.sk);
    return MnemonicWallet.fromMnemonic(mnemonic);
  }

  static generateMnenomic(): string {
    const account = algosdk.generateAccount();
    return algosdk.secretKeyToMnemonic(account.sk);
  }

  static fromMnemonic(mnemonic: string): MnemonicWallet {
    return new MnemonicWallet(mnemonic);
  }

  // encrypt(encryptFn: EncryptFN): string {
  //   return encryptFn(this.mnemonic);
  // }

  getAddress(): string {
    return this.address;
  }

  signTx(tx: algosdk.Transaction): Uint8Array {
    const signedTx = tx.signTxn(this.secretKey);
    return signedTx;
  }
}
