import React from "react";
import { MnemonicWallet } from "../../lib/wallet/MnemonicWallet";

export interface WalletDetailsProps {
  wallet: MnemonicWallet;
  mnemonicKey: string | null;
  secretKey: string | null;
  viewMnemonic: () => void;
  viewSecretKey: () => void;
  printWallet: () => void;
  removeWallet: () => void;
}

const WalletDetails: React.FC<WalletDetailsProps> = ({
  wallet,
  mnemonicKey,
  secretKey,
  viewMnemonic,
  viewSecretKey,
  printWallet,
  removeWallet,
}) => (
  <div className="grid grid-cols-1 px-6">
    <div className="w-full px-8 py-10 rounded-lg shadow-lg bg-tokodot-dark-primary text-white">
      <div className="break-all ">
        <p className="font-bold text-tokodot-gray text-xs uppercase">
          Algorand Address
        </p>
        <p className="mt-1 font-semibold text-white text-md">
          {wallet.getAddress()}
        </p>
      </div>
      <div className="mt-4">
        <p className="font-bold text-tokodot-gray text-xs uppercase">
          Secret Recovery Phrase
        </p>
        {!mnemonicKey && (
          <button
            onClick={viewMnemonic}
            className="px-6 py-2 mt-2 text-sm font-semibold text-white uppercase bg-tokodot-primary rounded-lg focus:outline-none"
          >
            View Secret Recovery Phrase
          </button>
        )}
        {mnemonicKey && (
          <div>
            <p className="bg-tokodot-dark-secondary text-white p-2">
              {mnemonicKey}
            </p>
            <button
              onClick={() => {
                navigator.clipboard.writeText(mnemonicKey);
              }}
              className="border-2 border-white bg-white text-tokodot-dark-primary font-bold rounded-lg text-lg py-2 px-6 focus:outline-none"
            >
              Copy
            </button>
          </div>
        )}
      </div>
      {/* <div className="mt-4 break-all">
        <p className="font-semibold text-sm mb-1">Secret key</p>
        {!secretKey && (
          <button
            onClick={viewSecretKey}
            className="px-6 py-2 text-sm font-semibold text-white uppercase bg-tokodot-purple"
          >
            View secret Key
          </button>
        )}
        {secretKey && (
          <p className="bg-tokodot-purple-light text-tokodot-purple-dark p-2">
            {secretKey}
          </p>
        )}
      </div> */}
      {/* <div className="mt-4">
        <p className="font-semibold text-sm mb-1">Export</p>
        <button
          onClick={printWallet}
          className="flex px-6 py-2 text-sm font-semibold bg-tokodot-purple-light uppercase text-tokodot-purple"
        >
          <PrintIcon />
          <span className="ml-2">Print all my wallet details</span>
        </button>
        <p></p>
      </div> */}
    </div>
    <div className="mt-12 font-bold w-full sm:w-128 text-white">
      <p>Remove my wallet from this browser</p>
      <div className="mt-3">
        <ul className="mt-4 text-white text-base font-bold list-disc px-8 py-4 list bg-tokodot-dark-primary rounded-lg">
          <li className="list-item">
            Make sure you have saved the secret recovery phrase before removing
            this wallet.
          </li>
          <li className="mt-4 list-item">
            If you do not store this phrase, you will permanently lose access to
            this wallet
          </li>
          <li className="mt-4 list-item">
            You can access this wallet again by importing your secret recovery
            phrase in an Algorand wallet
          </li>
        </ul>
        <button
          onClick={removeWallet}
          className="  bg-red-500 text-white font-semibold text-md rounded-lg px-3 py-2 mt-6"
        >
          Remove this wallet
        </button>
      </div>
    </div>
  </div>
);

const PrintIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <polyline points="6 9 6 2 18 2 18 9"></polyline>
    <path d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2"></path>
    <rect x="6" y="14" width="12" height="8"></rect>
  </svg>
);

export default WalletDetails;
