import { useParams } from "react-router";
import ExplorerAsset from "../containers/ExplorerAsset";
import { WalletStore } from "../store/WalletStore";

function ExplorerAssetPage() {
  const params: any = useParams();
  const wallet = WalletStore.useState((s) => s.wallet);

  if (!params.id) {
    return null;
  }

  return (
    <div className="md:container md:mx-auto flex justify-center">
      <div className="mt-12 mb-12 p-3">
        <ExplorerAsset assetId={params.id} wallet={wallet}></ExplorerAsset>
      </div>
    </div>
  );
}

export default ExplorerAssetPage;
