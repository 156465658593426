import React, { useEffect, useState, useRef, useCallback } from "react";
import { getActiveNetwork, setNetwork } from "../lib/network";
import { MainnetConfig, TestnetConfig } from "../lib/network/constants";
import { NetworkType } from "../lib/network/types";

const NetworkSwitch = ({
  onSwitch,
}: {
  onSwitch: (network: NetworkType) => void;
}) => {
  const [activeNetwork, setActiveNetwork] = useState<NetworkType>(() => {
    const networkPref = localStorage.getItem("tokodot:network") as NetworkType;
    if (networkPref) {
      return networkPref;
    }
    return getActiveNetwork().network;
  });

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const openToggle = () => setIsOpen(true);
  const closeToggle = () => setIsOpen(false);

  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    onSwitch(activeNetwork);
    if (activeNetwork === "mainnet") {
      localStorage.setItem("tokodot:network", "mainnet");
      setNetwork(MainnetConfig);
    } else {
      localStorage.setItem("tokodot:network", "testnet");
      setNetwork(TestnetConfig);
    }
    closeToggle();
  }, [activeNetwork, onSwitch]);

  const handleClickOutside = useCallback(
    (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        closeToggle();
      }
    },
    [ref]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.addEventListener("mousedown", handleClickOutside);
    };
  }, [ref, handleClickOutside]);

  const isMainnet = activeNetwork === "mainnet";

  return (
    <div
      ref={ref}
      className="relative  w-40 bg-tokodot-dark-primary px-2 py-1 rounded-lg text-white border-2 border-tokodot-dark-primary cursor-pointer"
    >
      <div
        className="flex justify-around items-center"
        onMouseDown={(e) => {
          e.preventDefault();
          toggle();
        }}
        onMouseEnter={() => openToggle()}
      >
        <Radio isMainnet={isMainnet} />
        <span className="text-lg font-semibold">
          {isMainnet ? "Mainnet" : "Testnet"}
        </span>
        <SelectDownIcon />
      </div>
      <ul
        className={`${
          !isOpen ? "hidden" : ""
        } absolute top-10 right-0 bg-tokodot-dark-secondary text-white w-36  rounded-xl shadow-xl p-0`}
        onMouseLeave={() => closeToggle()}
      >
        <button
          className={`${
            activeNetwork === "mainnet"
              ? "text-tokodot-primary font-medium"
              : ""
          } w-full hover:bg-gray-700 rounded-t-xl cursor-pointer px-3 py-3 focus:outline-none`}
          onClick={() => setActiveNetwork("mainnet")}
        >
          Mainnet
        </button>
        <button
          className={`${
            activeNetwork === "testnet"
              ? "text-tokodot-primary font-medium"
              : ""
          } w-full hover:bg-gray-700 rounded-b-xl cursor-pointer px-3 py-3 focus:outline-none`}
          onClick={() => setActiveNetwork("testnet")}
        >
          Testnet
        </button>
      </ul>
    </div>
  );
};

const Radio = ({ isMainnet }: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={`${isMainnet ? "text-green-400" : "text-yellow-400"}`}
  >
    <circle cx="12" cy="12" r="2"></circle>
    <path d="M16.24 7.76a6 6 0 0 1 0 8.49m-8.48-.01a6 6 0 0 1 0-8.49m11.31-2.82a10 10 0 0 1 0 14.14m-14.14 0a10 10 0 0 1 0-14.14"></path>
  </svg>
);

const SelectDownIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <polyline points="6 9 12 15 18 9"></polyline>
  </svg>
);

export default NetworkSwitch;
