import algosdk from "algosdk";
import { WalletNameType } from "./types";
import { WalletProvider } from "./Wallet";

export class ObservingWallet extends WalletProvider {
  type: WalletNameType;
  address: string;

  constructor(address: string) {
    super();
    this.type = "observing";
    this.address = address;
  }

  static fromAddress(address: string): ObservingWallet {
    return new ObservingWallet(address);
  }

  getAddress(): string {
    return this.address;
  }

  signTx(_: algosdk.Transaction): Uint8Array {
    throw "Cannot sign transaction from observing wallet";
  }
}
