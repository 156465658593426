import { QRCode } from "react-qrcode-logo";
import TokodotIcon from "../assets/tokodot_icon.svg";
import { WalletProvider } from "../lib/wallet/Wallet";
import PopupDailog from "./PopupDailog";

export interface QRCodeProps {
  wallet: WalletProvider;
  onClose: () => void;
}

const AddressQRCode = ({ wallet, onClose }: QRCodeProps) => {
  return (
    <PopupDailog title={"Receive"} onClose={onClose}>
      <div className="mt-4 p-3 rounded-xl flex items-center justify-center">
        <div className="bg-white p-4 rounded-lg">
          <QRCode
            value={wallet.getAddress()}
            size={256}
            logoImage={TokodotIcon}
            logoWidth={92}
            logoHeight={60}
            logoOpacity={0.5}
            qrStyle={"dots"}
            quietZone={0}
            fgColor="#000"
          />
        </div>
      </div>
      <div className="mt-6">
        <span className=" text-xl font-black text-white break-all">
          {wallet.getAddress()}
        </span>
      </div>
      <button
        onClick={() => {
          navigator &&
            navigator.clipboard &&
            navigator.clipboard.writeText(wallet.getAddress());
        }}
        className="w-full mt-6 flex items-center justify-center text-xl font-black py-3 bg-tokodot-primary text-white flex focus:outline-none rounded-lg"
      >
        Copy
      </button>
    </PopupDailog>
  );
};

// const CopyIcon = () => (
//   <svg
//     xmlns="http://www.w3.org/2000/svg"
//     width="24"
//     height="24"
//     viewBox="0 0 24 24"
//     fill="none"
//     stroke="currentColor"
//     strokeWidth="2"
//     strokeLinecap="round"
//     strokeLinejoin="round"
//     className="w-4"
//   >
//     <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
//     <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
//   </svg>
// );
export default AddressQRCode;
