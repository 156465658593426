import { useMachine } from "@xstate/react";
import algosdk, { algosToMicroalgos, microalgosToAlgos } from "algosdk";
import { useState } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { AssetBuilder } from "../lib/asset/AssetBuilder";
import {
  addAssetToDrop,
  DropArguments,
  generateDropAccount,
  optInAssetToDrop,
} from "../lib/drop/createDrop";
import { matchesAnyState, shareMachine } from "../lib/drop/drop";
import { AssetInfo } from "../lib/hooks/useAssetInfo";
import { WalletProvider } from "../lib/wallet/Wallet";
import AssetInput from "./AssetInput";
import PopupDailog from "./PopupDailog";

function ShareAsset(props: {
  wallet: WalletProvider;
  assets: { [assetId: string]: AssetInfo };
  closeShareForm: () => void;
  selectedAssetId?: string;
  algoPrice: number;
}) {
  const [shareState, sendToShareMachine] = useMachine(shareMachine, {
    actions: {
      sendAlgos: async (ctx, event) => {
        const success = await generateWallet();
        if (success) {
          sendToShareMachine({ type: "RESOLVE" });
        } else {
          sendToShareMachine({ type: "REJECT" });
        }
      },
      optIn: async (ctx, event) => {
        const success = await optInDrop();
        if (success) {
          sendToShareMachine({ type: "RESOLVE" });
        } else {
          sendToShareMachine({ type: "REJECT" });
        }
      },
      sendAsset: async (ctx, event) => {
        const success = await addTokenToWallet();
        if (success) {
          sendToShareMachine({ type: "RESOLVE" });
        } else {
          sendToShareMachine({ type: "REJECT" });
        }
      },
    },
  });

  const [algos, setAlgos] = useState(0);
  const [tokenValue, setTokenValue] = useState(0);
  const [genAccount, setGenAccount] = useState<algosdk.Account | null>(null);
  const [selectedToken, setSelectedToken] = useState<string>(() => {
    return props.selectedAssetId || Object.keys(props.wallet.assetBalance)[0];
  });
  const [dropId, setDropId] = useState<string | any>(null);
  const [dropDetails, setDropDetails] = useState<DropArguments | null>(null);

  const generateWallet = async () => {
    const newAccount = await generateDropAccount(props.wallet, algos);
    if (newAccount) {
      setGenAccount(newAccount);
      setDropId(Buffer.from(newAccount?.sk || "").toString("base64"));
      toast.success(
        `Successfully created a drop account with ${microalgosToAlgos(
          algos
        )} Algos.`
      );
      return true;
    } else {
      toast.error("Error. Try creating drop again.");
      return false;
    }
  };

  const optInDrop = async () => {
    if (!genAccount || !selectedToken) {
      return false;
    }
    const assetDecimals = props.assets[selectedToken].info.decimals;
    const success = await optInAssetToDrop(
      genAccount,
      selectedToken,
      Number(assetDecimals)
    );
    if (success) {
      toast.success(
        `Successfully opted-in ${props.assets[selectedToken].info.name} to this drop.`
      );
    } else {
      toast.error("Opt-In failed. Try again.");
    }
    return success;
  };

  const addTokenToWallet = async () => {
    if (!genAccount || !selectedToken) {
      return false;
    }
    const assetDecimals = props.assets[selectedToken].info.decimals;
    const success = await addAssetToDrop(
      props.wallet,
      genAccount,
      selectedToken,
      tokenValue,
      Number(assetDecimals)
    );
    if (success) {
      setDropDetails(success[dropId]);
      toast.success(
        `Successfully added ${tokenValue} ${props.assets[selectedToken].info.unitName} to this drop.`
      );
    } else {
      toast.error("Could not add asset to this drop. Try again.");
    }
    return success;
  };

  const isFinalState = matchesAnyState(
    ["errorOptIn", "errorSendAsset", "sentAsset"],
    shareState
  );

  if (!selectedToken) {
    return null;
  }

  const hasInputMinAlgos = algos >= algosToMicroalgos(0.5);
  const estimatedTxnFee = 1000 * 2;
  const hasEnoughAlgos =
    algos + estimatedTxnFee < props.wallet.getAlgoBalance();
  const hasEnoughAsset = tokenValue <= props.assets[selectedToken].balance;

  return (
    <PopupDailog
      title={isFinalState ? "Generated Drop" : "Share Token"}
      onClose={props.closeShareForm}
    >
      {!isFinalState && (
        <>
          <p className="text-lg font-semibold mb-4 text-white">
            Create a new <strong>drop wallet</strong> that is pre-funded and
            share them with others
          </p>
          <div className="flex items-center justify-between">
            <p className="ml-2 text-tokodot-primary font-bold text-sm">
              Algos to Send
            </p>
            <p className="text-white font-bold text-sm">
              You have:{" "}
              {microalgosToAlgos(Number(props.wallet.getAlgoBalance()))} ALGO
            </p>
          </div>
          <AssetInput
            algoPrice={0}
            wallet={props.wallet}
            className="w-full mt-1 bg-tokodot-dark-secondary px-4 py-2 font-bold text-xl text-white rounded-lg disabled:opacity-50"
            onChange={(value: number) => setAlgos(value)}
            decimals={6}
            initialValue={algosToMicroalgos(0.5)}
            disabled={!shareState.matches("idle")}
            algoOnly={true}
          />
          {!hasInputMinAlgos && (
            <p className="mt-2 text-red-600 text-xs font-bold">
              Need a minimum 0.5 ALGO
            </p>
          )}
          {!hasEnoughAlgos && (
            <p className="mt-2 text-red-600 text-xs font-bold">
              You do not have enough ALGO
            </p>
          )}
          <div className="flex items-center justify-between">
            <p className="mt-6 ml-2 text-tokodot-primary font-bold text-sm">
              Token
            </p>
          </div>

          <select
            className="w-full mt-2 bg-tokodot-dark-secondary text-white px-4 py-3 rounded-lg mb-4 font-bold text-lg disabled:opacity-50"
            value={selectedToken || ""}
            onChange={(e: any) => setSelectedToken(e.target.value)}
            disabled={!shareState.matches("idle")}
          >
            {Object.keys(props.assets).map((assetId: string) => {
              return (
                <option value={assetId} key={assetId}>
                  {props.assets[assetId].info.name} - {assetId}
                </option>
              );
            })}
          </select>
          <div className="flex items-center justify-between">
            <p className="ml-2 text-tokodot-primary font-bold text-sm">
              Amount
            </p>
            {selectedToken && (
              <p className="text-white font-bold text-sm">
                You have: {props.assets[selectedToken].displayBalance}{" "}
                {props.assets[selectedToken].info.unitName}
              </p>
            )}
          </div>
          <AssetInput
            initialValue={0}
            wallet={props.wallet}
            algoPrice={props.algoPrice}
            className="w-full mt-1 bg-tokodot-dark-secondary px-4 py-2 font-bold text-lg text-white rounded-lg disabled:opacity-50"
            decimals={Number(props.assets[selectedToken]?.info.decimals)}
            onChange={(value: number) => setTokenValue(value)}
            disabled={!shareState.matches("idle")}
            algoOnly={false}
          />
          {!hasEnoughAsset && (
            <p className="mt-2 text-red-600 text-xs font-bold">
              You do not have enough {props.assets[selectedToken].info.unitName}
            </p>
          )}

          <button
            type="button"
            className="disabled:opacity-40 w-full bg-tokodot-primary text-white px-3 py-3 font-black text-lg mt-4 flex items-center justify-center  rounded-lg"
            onClick={() => {
              if (shareState.matches("idle")) {
                sendToShareMachine({ type: "SEND_ALGO" });
              }
              if (shareState.matches("sentAlgos")) {
                sendToShareMachine({ type: "OPTIN" });
              }
              if (shareState.matches("optedIn")) {
                sendToShareMachine({ type: "SEND_ASSET" });
              }
              if (
                matchesAnyState(
                  ["failedSendAlgos", "failedOptIn", "failedSendAsset"],
                  shareState
                )
              ) {
                sendToShareMachine({ type: "RETRY" });
              }
            }}
            disabled={
              matchesAnyState(
                [
                  "errorSendAlgos",
                  "errorOptIn",
                  "errorSendAsset",
                  "sendingAlgos",
                  "optingIn",
                  "sendingAsset",
                  "sentAsset",
                ],
                shareState
              ) ||
              !hasEnoughAlgos ||
              !hasInputMinAlgos ||
              !hasEnoughAsset
            }
          >
            {/* loading states */}
            {matchesAnyState(
              ["sendingAlgos", "optingIn", "sendingAsset"],
              shareState
            ) && <Spinner />}
            {shareState.matches("sendingAlgos") && "Sending Algos"}
            {shareState.matches("optingIn") && "Opting In"}
            {shareState.matches("sendingAsset") && "Sending Token"}

            {/* action states */}
            {shareState.matches("idle") && "Create Drop"}
            {shareState.matches("sentAlgos") && "Opt In"}
            {shareState.matches("optedIn") && "Send Token"}

            {/* failed states */}
            {shareState.matches("failedSendAlgos") &&
              "Failed to create drop. Try again."}
            {shareState.matches("failedOptIn") &&
              "Failed to opt-in. Try again."}
            {shareState.matches("failedSendAsset") &&
              "Failed to send token. Try again."}

            {/* final error states */}
            {shareState.matches("errorSendAlgos") && "Error while create drop"}
            {shareState.matches("errorOptIn") && "Error to opt-in."}
            {shareState.matches("errorSendAsset") && "Error to send token."}

            {/* success state */}
            {shareState.matches("sentAsset") && "Success"}
          </button>
        </>
      )}
      {isFinalState && (
        <>
          {!shareState.matches("sentAsset") && (
            <p>Failed while creating drop.</p>
          )}
          {shareState.matches("sentAsset") && (
            <>
              <p className="font-black text-xl text-white mt-4">
                Successfully created your drop.
              </p>
              <p className="font-black text-xl text-white mt-2">
                Share the link with your friend.
              </p>
              <p className="font-black text-xl text-white mt-2">
                Make sure you copy the link before closing this screen.
              </p>
            </>
          )}
          <p className="text-tokodot-primary text-sm font-bold mt-3">
            Share Link
          </p>
          <p className="text-white text-md font-bold w-full break-all">
            {window.location.protocol}://{window.location.host}/wallet?drop=
            {encodeURIComponent(dropId)}
          </p>
          <p className=" text-tokodot-primary text-sm font-bold mt-3">
            Drop Balance
          </p>
          {dropDetails && (
            <p className="text-white font-bold text-md mt-1">
              {dropDetails.algo} ALGO
            </p>
          )}
          <p className="text-tokodot-primary text-sm font-bold mt-3">
            Drop Tokens
          </p>
          {dropDetails &&
            Object.keys(dropDetails.assets).map((assetId) => (
              <p className="text-white font-bold text-md mt-1" key={assetId}>
                {dropDetails.assets[assetId].amount}{" "}
                {props.assets[assetId].info.unitName}
              </p>
            ))}
          <button
            type="button"
            className="disabled:opacity-40 w-full bg-tokodot-primary text-white px-3 py-3 font-black text-lg mt-4 flex items-center justify-center  rounded-lg"
            onClick={() =>
              window.navigator &&
              window.navigator.clipboard &&
              window.navigator.clipboard.writeText(`${
                window.location.protocol
              }://${window.location.host}/wallet?drop=
            ${encodeURIComponent(dropId)}`)
            }
          >
            Copy
          </button>
        </>
      )}
    </PopupDailog>
  );
}

const Spinner = () => (
  <svg
    className="animate-spin-fast -ml-1 mr-3 h-5 w-5 text-white"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
);

export default ShareAsset;
