import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { WalletStore } from "../store/WalletStore";
import { WhiteCard, FiveXFiveGrid } from "../components/common";
import { Routes } from "../App";

/* Randomize array in-place using Durstenfeld shuffle algorithm */
function shuffleArray(array: Array<string>) {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}

const VerifyWallet = (props: any) => {
  // useState returns a getter(inputs) & a setter(setInputs)
  const walletKey = WalletStore.useState((s) => s.mnemonicKey);

  const [correctSelected, setCorrectSelected] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(1);

  const [randomNumber, setRandomNumber] = useState(() => {
    return Math.floor(Math.random() * 25) + 1;
  });
  const [isCorrect, setIsCorrect] = useState<boolean | null>(null);
  const [randomizedWords] = useState(() => {
    const words = walletKey.split(" ");
    shuffleArray(words);
    return words;
  });

  const history = useHistory();

  const switchToLockWalletPage = () => {
    history.push(Routes.LOCK);
  };

  const verifyWord = (word: string) => {
    if (word === walletKey.split(" ")[randomNumber - 1]) {
      setCorrectSelected(correctSelected + 1);
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setCorrectSelected(0);
      setCurrentQuestion(1);
    }
    setRandomNumber(Math.floor(Math.random() * 25) + 1);
  };

  useEffect(() => {
    if (correctSelected === 3) {
      setIsCorrect(true);
      switchToLockWalletPage();
    }
  }, [correctSelected]);

  useEffect(() => {
    if (walletKey === "") {
      history.replace(Routes.CREATE);
    }
  }, [history, walletKey]);

  return (
    <WhiteCard>
      {!isCorrect && (
        <div className="m-2">
          <div>
            <h1 className="text-white text-left text-xl font-bold ml-2">
              Question {currentQuestion} of 3
            </h1>
            <p className="text-white text-left ml-2 mt-5 mb-5">
              Select word number {randomNumber} from your secret recovery phrase
            </p>
          </div>
          <FiveXFiveGrid>
            {walletKey &&
              randomizedWords.map((word) => (
                <button
                  className="p-3 m-2 w-auto h-auto text-sm text-center overflow-hidden font-semibold bg-tokodot-dark-secondary text-white hover:bg-purple-400 rounded"
                  onClick={() => verifyWord(word)}
                >
                  {word}
                </button>
              ))}
          </FiveXFiveGrid>
          {process.env.NODE_ENV !== "production" && walletKey && (
            <p className="p-2 w-auto m-2 font-semibold text-left text-purple-900 bg-purple-100">
              {walletKey}
            </p>
          )}
        </div>
      )}
      {/* comment out the button before release*/}
      {process.env.NODE_ENV !== "production" && (
        <div className="flex justify-center">
          <button
            onClick={() => switchToLockWalletPage()}
            className="bg-tokodot-primary text-white border-2 border-tokodot-primary font-semibold rounded-lg text-lg py-2 px-8"
          >
            Next
          </button>
        </div>
      )}
    </WhiteCard>
  );
};

export default VerifyWallet;
